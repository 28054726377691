import { toast, Toaster, ToastBar } from "react-hot-toast";
import "./ToasterMain.scss";

const dismissButtonStyle = {
  backgroundColor: "inherit",
  border: "none",
  borderLeft: "1px solid rgba(0, 0, 0, 0.15)",
  color: "inherit",
  padding: "10px 0 10px 10px",
  fontSize: "16px",
  fontFamily: "good-timing",
  cursor: "pointer",
  outline: "none",
  height: "100%",
  transition: "border-color 0.3s ease",
  ":hover": {
    borderLeftColor: "rgba(0, 0, 0, 0.8)",
  },
};

const getMessageStyle = (type) => {
  const baseStyle = {
    fontSize: "18px",
    color: "inherit",
    fontWeight: "600",
    margin: "0 10px",
  };

  switch (type) {
    case "error":
      return { ...baseStyle, color: "inherit" };
    case "success":
      return { ...baseStyle, color: "inherit" };
    case "loading":
      return { ...baseStyle, fontWeight: "normal" };
    default:
      return baseStyle;
  }
};

const FadedBorderButton = ({ onClick, children }) => (
  <button style={dismissButtonStyle} onClick={onClick}>
    {children}
  </button>
);

function ToasterMain() {
  return (
    <Toaster
      toastOptions={{
        duration: 5000, // toast visible for 5s
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              <span style={getMessageStyle(t.type)}>{message}</span>
              {t.type !== "loading" && !t.data?.isTeamMgmtCustom && (
                <FadedBorderButton onClick={() => toast.dismiss(t.id)}>
                  Dismiss
                </FadedBorderButton>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}

export default ToasterMain;