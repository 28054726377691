import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./ModalExerciseComplete.scss";
import partyCracker from "../../../assets/partyCracker.svg";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";

const ModalExerciseComplete = () => {
  const cx = classNames.bind(styles);
  const [countdown, setCountdown] = useState<number>(10);
  const navigate = useNavigate();

  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_information_instructions");
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      navigate("/home");
    }
  }, [countdown, navigate]);

  return (
    <div className={cx("student-well-done-modal modal-overlay")}>
      <div className={cx("student-well-done-rec-container")}>
        <img
          className={cx("student-well-done-party-cracker")}
          src={partyCracker}
          alt="party-cracker"
        />
        <div className={cx("student-well-done-title")}>Well done!</div>

        <div className={cx("student-well-done-subtitle")}>
          You have successfully completed the exercise.
        </div>
        <div className={cx("student-redirect-subtitle")}>
          Redirecting in {countdown} seconds...
        </div>
      </div>
    </div>
  );
};

export default ModalExerciseComplete;
