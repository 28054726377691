import React, { ChangeEvent, useState } from "react";
import classNames from "classnames/bind";
import styles from "./DashboardChangeParentsEmail.scss";
import { changeParentEmail } from "services/api/studentApi";
import { useLogOut } from "hooks/useCheckExpiredToken";
import { isValidEmail } from "utils/emailUtils";
import { useStudentDashboardStore } from "store/student/useStudentDashboardStore";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";
import ModalEmailVerification from "../modalEmailVerification/ModalEmailVerification";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";

const DashboardChangeParentsEmail = () => {
  const cx = classNames.bind(styles);
  const { doLogOut } = useLogOut();
  const { setActiveProfileSubpage } = useStudentDashboardStore();
  const {
    studentProfile,
    parentsConfirmEmail,
    setStudentProfile,
    setParentsConfirmEmail,
    setShowModal,
    showModal,
  } = useStudentSetupStore();

  const [newParentEmail, setNewParentEmail] = useState<string>("");
  const [newParentEmailTwo, setNewParentEmailTwo] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const handleNewParentEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewParentEmail(event.target.value);
    setErrorMsg("");
  };

  const handleConfirmParentEmailChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setNewParentEmailTwo(event.target.value);
    setParentsConfirmEmail(event.target.value);
    setErrorMsg("");
  };

  const isFormValid = (): boolean => {
    return (
      newParentEmail === parentsConfirmEmail &&
      isValidEmail(newParentEmail) &&
      isValidEmail(parentsConfirmEmail)
    );
  };

  const handleVerifyButtonClick = async () => {
    if (isFormValid()) {
      try {
        const response = await changeParentEmail(newParentEmail);
        if (response && response.results) {
          setStudentProfile({
            ...studentProfile,
            parent_email: newParentEmail,
          });
          setShowModal(true);
          console.log("changeParentEmail API:", response);
        } else {
          setErrorMsg("Unexpected response from server. Please try again.");
        }
      } catch (error) {
        console.error("changeParentEmail API:", error);

        if (
          error instanceof Error &&
          (error.message === "Token is required but not found" ||
            error.message === "Signature has expired")
        ) {
          console.error("Caught token issue, logging out...");
          doLogOut();
        } else {
          setErrorMsg("Failed to verify parent email. Please try again.");
        }
      }
    } else {
      setErrorMsg("Emails do not match or are invalid.");
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setActiveProfileSubpage("profile");
  };

  return (
    <>
      <div className={cx("st-change-parents-email-container")}>
        <div
          className={cx(
            "st-change-parents-email-rectangle-container white-panel content",
            errorMsg && "error-height"
          )}
        >
          <div className={cx("st-change-parents-email-contents")}>
            <div className={cx("st-change-parents-email-title")}>
              Change Parent's Email
            </div>

            <div className={cx("st-change-parents-email-subtitle")}>
              Existing Parent's Email
            </div>
            <input
              className={cx("st-change-parents-existing-email input-filled")}
              type="text"
              value={studentProfile.parent_email}
              disabled
            />

            <div className={cx("st-change-parents-email-subtitle")}>
              Parent's New Email
            </div>
            <input
              className={cx("st-change-parents-email-input input")}
              type="text"
              placeholder="Type your new parent's email here"
              value={newParentEmail}
              onChange={handleNewParentEmailChange}
            />

            <div className={cx("st-change-parents-email-subtitle")}>
              Confirm Parent's New Email
            </div>
            <input
              className={cx("st-change-parents-email-input input")}
              type="text"
              placeholder="Confirm your new parent's email here"
              value={newParentEmailTwo}
              onChange={handleConfirmParentEmailChange}
            />

            {errorMsg && (
              <div className={cx("st-change-parents-email-error")}>
                {errorMsg}
              </div>
            )}

            <div className={cx("st-change-parents-email-verify-btn-container")}>
              <StudentButtonCTA
                type={isFormValid() ? "primary" : "inactive"}
                text="Verify"
                onClick={handleVerifyButtonClick}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && <ModalEmailVerification onClose={handleModalClose} />}
    </>
  );
};

export default DashboardChangeParentsEmail;
