import { useEffect, useCallback, useRef } from "react";

type InactivityCallback = () => void;

const useInactivityTimeout = (
  onMicblink: InactivityCallback,
  onInactivity: InactivityCallback,
  micblinkTime = 5 * 60 * 1000,
  inactivityTime = 5 * 60 * 1000
) => {
  const inactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const micblinkTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const initialStartTimeRef = useRef<number | null>(null);

  const resetTimer = useCallback(() => {
    const currentStartTime = Date.now();

    if (initialStartTimeRef.current === null) {
      initialStartTimeRef.current = currentStartTime;
    }

    if (inactivityTimeoutRef.current)
      clearTimeout(inactivityTimeoutRef.current);
    if (micblinkTimeoutRef.current) clearTimeout(micblinkTimeoutRef.current);

    if (initialStartTimeRef.current) {
      micblinkTimeoutRef.current = setTimeout(() => {
        onMicblink();

        inactivityTimeoutRef.current = setTimeout(() => {
          onInactivity();
        }, inactivityTime);
      }, micblinkTime);
    }
  }, [micblinkTime, inactivityTime, onMicblink, onInactivity]);

  useEffect(() => {
    const addEventListeners = () => {
      document.addEventListener("keydown", resetTimer);
      document.addEventListener("click", resetTimer);
    };

    addEventListeners();

    resetTimer();

    return () => {
      if (micblinkTimeoutRef.current) clearTimeout(micblinkTimeoutRef.current);
      if (inactivityTimeoutRef.current)
        clearTimeout(inactivityTimeoutRef.current);

      document.removeEventListener("keydown", resetTimer);
      document.removeEventListener("click", resetTimer);
    };
  }, [resetTimer]);

  return null;
};

export default useInactivityTimeout;
