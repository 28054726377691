import { create } from "zustand";
import { useEffect } from "react";

interface MediaStreamState {
  stream: MediaStream | undefined;
  start: (audio: boolean, video: boolean) => void;
  stop: () => void;
}

export const useMediaStreamStore = create<MediaStreamState>((set) => ({
  stream: undefined,
  start: async (audio, video) => {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio,
      video,
    });
    set({ stream: mediaStream });
  },
  stop: () => {
    const { stream } = useMediaStreamStore.getState();
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    set({ stream: undefined });
  },
}));

export const useMediaStreamCleanup = () => {
  const stream = useMediaStreamStore((state) => state.stream);

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);
};
