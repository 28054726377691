import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./MainDashboard.scss";
import Dropdown from "react-bootstrap/Dropdown";
import NavigationBar from "../../../shared/navigationBar/NavigationBar";
import searchIcon from "../../../assets/searchIcon.svg";

// DEPRECATED: previously @material-ui/core was installed,
// but it's removed as it conflicts with React v18
// import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import MuiTableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // MuiTableHead,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";

import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainDashboard = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(5);

  const checkIfCreateSingleOrganisationIsTrue = localStorage.getItem(
    "create-organisation"
  );

  const checkIfBatchUploadOrganisationIsTrue =
    localStorage.getItem("batch-upload-org");

  useEffect(() => {
    if (checkIfCreateSingleOrganisationIsTrue === "true") {
      showtoastMsgIfSingleOrganisationCreated();
      localStorage.setItem("create-organisation", "");
    }
    if (checkIfBatchUploadOrganisationIsTrue === "true") {
      showtoastMsgIfBatchUploadOrgCreated();
      localStorage.setItem("batch-upload-org", "");
    }
  }, [
    checkIfBatchUploadOrganisationIsTrue,
    checkIfBatchUploadOrganisationIsTrue,
  ]);

  const showtoastMsgIfSingleOrganisationCreated = () => {
    toast.success("New Organisation has been successfully created.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      style: {
        backgroundColor: "#1BBF8D",
      },
    });
  };

  const showtoastMsgIfBatchUploadOrgCreated = () => {
    toast.success("File has been successfully uploaded.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      style: {
        backgroundColor: "#1BBF8D",
      },
    });
  };

  function handleChangePage(event: any, newpage: any) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event: any) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  // DEPRECATED: previously @material-ui/core was installed,
  // but it's removed as it conflicts with React v18
  // const useStyles = makeStyles({
  //   table: {
  //     opacity: 1,
  //     borderRadius: 2,
  //   },
  // });

  function createData(
    organisationName: string,
    students: number,
    teachers: number,
    classes: number,
    admins: number,
    action: any
  ) {
    return {
      organisationName: organisationName,
      students: students,
      teachers: teachers,
      classes: classes,
      admins: admins,
      action: action,
    };
  }

  const rows = [
    createData(
      "Admiralty Primary School",
      280,
      14,
      21,
      2,
      <a className={cx("so-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      "Ahmad Ibrahim Primary School",
      280,
      14,
      21,
      2,
      <a className={cx("so-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      "Alexandra Primary School",
      280,
      14,
      21,
      2,
      <a className={cx("so-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      "Anchor Green Primary School",
      280,
      14,
      21,
      2,
      <a className={cx("so-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      "Ang Mo Kio Primary School",
      280,
      14,
      21,
      2,
      <a className={cx("so-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      "Bendemeer Primary School",
      280,
      14,
      21,
      2,
      <a className={cx("so-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      "Blangah Rise Primary School",
      280,
      14,
      21,
      2,
      <a className={cx("so-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      "Bendemeer Primary School",
      280,
      14,
      21,
      2,
      <a className={cx("so-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      "Crescent Girls Primary School",
      280,
      14,
      21,
      2,
      <a className={cx("so-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      "Duman Primary School",
      280,
      14,
      21,
      2,
      <a className={cx("so-main-dashboard-link")}>Full Details</a>
    ),
  ];

  // DEPRECATED: previously @material-ui/core was installed,
  // but it's removed as it conflicts with React v18
  // const classes = useStyles();

  const renderTableHead = () => {
    return (
      <TableHead className={cx("so-main-dashboard-table-head-container")}>
        <TableRow>
          <TableCell className={cx("so-main-dashboard-table-head-labels")}>
            <TableSortLabel>Organisation Name</TableSortLabel>
          </TableCell>
          <TableCell
            className={cx("so-main-dashboard-table-head-labels")}
            align="left"
          >
            <TableSortLabel>Students</TableSortLabel>
          </TableCell>
          <TableCell
            className={cx("so-main-dashboard-table-head-labels")}
            align="left"
          >
            <TableSortLabel>Teachers</TableSortLabel>
          </TableCell>
          <TableCell
            className={cx("so-main-dashboard-table-head-labels")}
            align="left"
          >
            <TableSortLabel>Classes</TableSortLabel>
          </TableCell>
          <TableCell
            className={cx("so-main-dashboard-table-head-labels")}
            align="left"
          >
            <TableSortLabel>Admins</TableSortLabel>
          </TableCell>

          <TableCell
            className={cx("so-main-dashboard-table-head-labels")}
            align="left"
          >
            <TableSortLabel>Action</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const renderWholeTable = () => {
    return (
      <TableContainer className={cx("so-main-dashboard-table-container")}>
        <Table>
          {renderTableHead()}
          <TableBody>
            {rows.map((row) => (
              <TableRow
                className={cx("so-main-dashboard-table-row")}
                key={row.organisationName}
              >
                <TableCell
                  className={cx("so-main-dashboard-table-cell")}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.organisationName}
                </TableCell>
                <TableCell
                  className={cx("so-main-dashboard-table-cell")}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.students}
                </TableCell>
                <TableCell
                  className={cx("so-main-dashboard-table-cell")}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.teachers}
                </TableCell>
                <TableCell
                  className={cx("so-main-dashboard-table-cell")}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.classes}
                </TableCell>
                <TableCell
                  className={cx("so-main-dashboard-table-cell")}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.admins}
                </TableCell>
                <TableCell
                  className={cx("so-main-dashboard-table-cell")}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.action}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderSearchBar = () => {
    return (
      <div className={cx("so-main-dashboard-searchbar-container")}>
        <div className={cx("so-main-dashboard-merge")}>
          <input
            className={cx("so-main-dashboard-input")}
            placeholder="Search by Organisation"
          />
          <div className={cx("so-main-dashboard-search-icon-box")}>
            <img
              style={{ marginLeft: "22px", marginTop: "18px" }}
              className={cx("so-main-dashboard-search-icon")}
              src={searchIcon}
              alt="system-owner-dashboard-search-icon"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDropdownButton = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          className={cx("so-main-dashboard-btn")}
          variant="success"
          id="dropdown-basic"
        >
          Create New &nbsp; &nbsp; &nbsp; &nbsp;
        </Dropdown.Toggle>

        <Dropdown.Menu className={cx("so-main-dashboard-btn-menu")}>
          <Dropdown.Item className={cx("so-main-dashboard-btn-menu-item")}>
            Create Single
          </Dropdown.Item>
          <Dropdown.Item className={cx("so-main-dashboard-btn-menu-item")}>
            Upload Multiple
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderPagination = () => {
    return (
      <Stack spacing={2}>
        <Pagination
          className={cx("so-main-dashboard-table-count")}
          count={10}
          shape="rounded"
        />
      </Stack>
    );
  };

  return (
    <div className={cx("so-main-dashboard-container")}>
      <div className={cx("so-main-dashboard-nav")}>
        <NavigationBar />
      </div>

      <div className={cx("so-main-dashboard-white-container")}>
        <ToastContainer />
        <div className={cx("so-main-dashboard-title-btn-container")}>
          <div className={cx("so-main-dashboard-title")}>Organisations</div>
          {renderDropdownButton()}
        </div>
        {renderSearchBar()}
        {renderWholeTable()}
        <div className={cx("so-main-dashboard-pagination-container")}>
          <TablePagination
            className={cx("so-main-dashboard-table-pagination")}
            sx={{
              "& .MuiTablePagination-displayedRows": { display: "none" },
              "& .MuiTablePagination-actions": { display: "none" },
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rpg}
            page={pg}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {renderPagination()}
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
