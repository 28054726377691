import React from "react";
import classNames from "classnames/bind";
import styles from "./StudentButtonCTA.scss";

interface Props {
  type: "primary" | "secondary" | "inactive";
  text: string | null;
  onClick: () => void;
}

const StudentButtonCTA = ({ type, text, onClick }: Props) => {
  const cx = classNames.bind(styles);

  return (
    <div
      className={cx("studentbtn-cta", {
        "studentbtn-cta--primary": type === "primary",
        "studentbtn-cta--secondary": type === "secondary",
        "studentbtn-cta--inactive": type === "inactive",
      })}
      role="button"
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default StudentButtonCTA;
