import React from "react";
import classNames from "classnames/bind";
import styles from "./StudentResetScreen.scss";
import ModalResetEmail from "components/student/modalResetEmail/ModalResetEmail";

const StudentResetScreen = () => {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("student st-reset-screen screen")}>
      <ModalResetEmail />
    </div>
  );
};

export default StudentResetScreen;
