import { create } from "zustand";
import { useEffect } from "react";

interface AudioAnalyserStore {
  analyser: AnalyserNode | null;
  setAnalyser: (analyser: AnalyserNode) => void;
  audioContext: AudioContext | null;
  setAudioContext: (audioContext: AudioContext) => void;
}

export const useAudioAnalyserStore = create<AudioAnalyserStore>((set) => ({
  analyser: null,
  setAnalyser: (analyser) => set({ analyser }),
  audioContext: null,
  setAudioContext: (audioContext) => set({ audioContext }),
}));

export const useAudioAnalyserSetup = () => {
  const { setAnalyser, setAudioContext } = useAudioAnalyserStore();

  useEffect(() => {
    const audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    const analyserNode = audioContext.createAnalyser();

    setAudioContext(audioContext);
    setAnalyser(analyserNode);

    let mediaStream: MediaStream | null = null;

    const getAudioStream = async () => {
      try {
        mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        const mediaStreamSource =
          audioContext.createMediaStreamSource(mediaStream);
        mediaStreamSource.connect(analyserNode);
      } catch (error) {
        console.error("Error accessing the microphone:", error);
      }
    };

    getAudioStream();

    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }

      analyserNode.disconnect();
      if (audioContext.state !== "closed") {
        audioContext.close();
      }
    };
  }, [setAnalyser, setAudioContext]);
};
