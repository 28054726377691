import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./StudentSetupScreen.scss";
import { useNavigate } from "react-router-dom";
import NavigationBarHomeLogout from "shared/navigationBarHomeLogout/NavigationBarHomeLogout";
import studentMailIcon from "assets/studentMailIcon.svg";
import studentMicIcon from "assets/studentMicIcon.svg";
import successCheckedIcon from "assets/successChecked.svg";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";
import AddParentsEmail from "../../../components/student/setupAddParentsEmail/SetupAddParentsEmail";
import MicTest from "../../../components/student/setupMicTest/SetupMicTest";
import ModalEmailVerification from "components/student/modalEmailVerification/ModalEmailVerification";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";
import useMicPermissionStore from "store/student/checkMicPermissionStore";
import { getStudentProfile } from "services/api/studentApi";

const StudentSetupScreen = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const {
    isTokenExpired,
    studentProfile,
    showComponent,
    showModal,
    finishedMicTest,
    setIsTokenExpired,
    setStudentProfile,
    setShowComponent,
    setShowModal,
  } = useStudentSetupStore();
  const { micTestStatus, micPermissionState, setMicPermissionState } =
    useMicPermissionStore();
  const [startBtnVisible, setStartBtnVisible] = useState<boolean>(false);

  const addBtnHandler = () => {
    if (!studentProfile.parent_email) {
      setShowComponent("addParentsEmail");
    }
  };

  const testBtnHandler = () => {
    setShowComponent("micTest");
  };

  const startBtnHandler = async () => {
    navigate("/home");
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const fetchStudentProfile = async () => {
    try {
      const profile = await getStudentProfile();
      setStudentProfile(profile);
      console.log("fetchStudentProfile", profile);
    } catch (error) {
      console.error("getStudentProfile:", error);
    }
  };

  useEffect(() => {
    setShowComponent("default");
    setShowModal(false);
    const handleVisibilityChange = () => {
      if (document.hidden) {
        return;
      } else {
        window.location.reload();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    fetchStudentProfile();
  }, [showModal]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (studentProfile.next_parent_token_request) {
        const tokenExpirationDate = new Date(
          studentProfile.next_parent_token_request
        );
        const currentDate = new Date();

        if (tokenExpirationDate < currentDate) {
          setIsTokenExpired(true);
        } else {
          setIsTokenExpired(false);
          const timeoutDuration =
            tokenExpirationDate.getTime() - currentDate.getTime();
          setTimeout(() => {
            setIsTokenExpired(true);
          }, timeoutDuration);
        }
      }
    };

    checkTokenExpiration();
  }, [studentProfile]);

  useEffect(() => {
    const checkMicPermissions = async () => {
      try {
        if (navigator.userAgent.indexOf("Firefox") > -1) {
          if (micPermissionState === null || micPermissionState === "prompt") {
            setMicPermissionState("prompt");
          }
        } else {
          const permissionStatus = await navigator.permissions.query({
            name: "microphone" as PermissionName,
          });
          setMicPermissionState(permissionStatus.state);
        }
      } catch (error) {
        console.error("Error checking microphone permissions:", error);
      }
    };

    checkMicPermissions();
  }, [micPermissionState, setMicPermissionState]);

  useEffect(() => {
    if (studentProfile.parent_email && micPermissionState === "granted") {
      setStartBtnVisible(true);
    } else {
      setStartBtnVisible(false);
    }
  }, [studentProfile, micTestStatus, micPermissionState]);

  return (
    <div className={cx("student st-setup-screen screen")}>
      {showModal && <ModalEmailVerification onClose={handleModalClose} />}
      <NavigationBarHomeLogout />
      <div className={cx("st-welcome-container")}>
        {showComponent === "default" && (
          <div className={cx("st-welcome-content content")}>
            <div className={cx("st-welcome-title")}>
              Hello {studentProfile.first_name} {studentProfile.last_name},
              welcome!
            </div>
            <div className={cx("st-welcome-subtitle")}>
              Let's do two things first before we start the exercises. Ask your
              parents to help you with these steps.
            </div>
            <div className={cx("st-welcome-wrapper")}>
              <div className={cx("st-welcome-rectangle-wrapper")}>
                <img
                  className={cx("st-welcome-mail-icon")}
                  src={studentMailIcon}
                  alt="student-welcome-mail-icon"
                />
                <div className={cx("st-welcome-label-container")}>
                  <div className={cx("st-welcome-label-title")}>
                    1. Parent's Email
                  </div>
                  <div className={cx("st-welcome-label-subtitle")}>
                    Make your account safer by adding your parent's email. Click
                    "Add" and enter their email.
                  </div>
                  {!isTokenExpired &&
                    studentProfile.next_parent_token_request && (
                      <>
                        <div
                          className={cx("st-welcome-try-again-email-subtitle")}
                        >
                          We sent an email verification link to your parent's
                          email. If you don't receive it, try again in a little
                          while.
                        </div>
                      </>
                    )}
                </div>
              </div>
              <div className={cx("st-welcome-icon-label-container")}>
                {studentProfile.parent_email && (
                  <>
                    <img
                      className={cx("st-welcome-add-btn")}
                      src={successCheckedIcon}
                      alt="student-welcome-test-btn"
                    />
                    <div className={cx("st-welcome-success-label")}>
                      Success
                    </div>
                  </>
                )}
                {!studentProfile.parent_email && (
                  <>
                    {studentProfile.next_parent_token_request ? (
                      <>
                        <StudentButtonCTA
                          type={isTokenExpired ? "primary" : "inactive"}
                          text="Add"
                          onClick={isTokenExpired ? addBtnHandler : () => {}}
                        />
                      </>
                    ) : (
                      <>
                        <StudentButtonCTA
                          type="primary"
                          text="Add"
                          onClick={addBtnHandler}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className={cx("st-welcome-wrapper")}>
              <div className={cx("st-welcome-rectangle-wrapper")}>
                <img
                  className={cx("st-welcome-mic-icon")}
                  src={studentMicIcon}
                  alt="student-welcome-mic-icon"
                />
                <div className={cx("st-welcome-label-container")}>
                  <div className={cx("st-welcome-label-title")}>
                    2. Test Your Microphone
                  </div>
                  <div className={cx("st-welcome-label-subtitle")}>
                    Make sure your Microphone works well. Click on the "Test"
                    button to check it.
                  </div>
                  {navigator.userAgent.indexOf("Firefox") > -1 && (
                    <div className={cx("st-welcome-firefox-warning")}>
                      Firefox detected. Microphone may not be compatible.
                      <br />
                      You may still proceed if Start button is available
                    </div>
                  )}
                  {micPermissionState === "denied" && (
                    <div
                      className={cx("st-welcome-blocked-microphone-subtitle")}
                    >
                      We can't use your microphone right now. To use it, we need
                      your parents' help. Please ask them to let you enable the
                      microphone so you can use this feature.
                    </div>
                  )}
                </div>
              </div>

              <div className={cx("st-welcome-icon-label-container")}>
                {micPermissionState === "granted" && finishedMicTest ? (
                  <>
                    <img
                      className={cx("st-welcome-test-btn")}
                      src={successCheckedIcon}
                      alt="student-welcome-test-btn"
                    />
                    <div className={cx("st-welcome-success-label")}>
                      Success
                    </div>
                  </>
                ) : (
                  <StudentButtonCTA
                    text="Test"
                    type={
                      micPermissionState === "denied" ? "inactive" : "primary"
                    }
                    onClick={testBtnHandler}
                  />
                )}
              </div>
            </div>

            {startBtnVisible && (
              <div className={cx("st-welcome-start-btn-container")}>
                <StudentButtonCTA
                  text="Start"
                  type="primary"
                  onClick={startBtnHandler}
                />
              </div>
            )}
          </div>
        )}
        {showComponent === "addParentsEmail" && <AddParentsEmail />}
        {showComponent === "micTest" && <MicTest />}
      </div>
    </div>
  );
};

export default StudentSetupScreen;
