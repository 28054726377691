import React, { useEffect, useState } from "react";
import DashboardExerciseCard from "../dashboardExerciseCard/DashboardExerciseCard";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";
import classNames from "classnames/bind";
import styles from "./DashboardHistory.scss";
import noExercisesIcon from "assets/no_exercises.svg";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";
import { getExerciseHistory } from "services/api/studentApi";

const DashboardHistory = () => {
  const { pastExercises, setPastExercises } = useStudentExerciseStore();
  const cx = classNames.bind(styles);

  const [selectedFilter, setSelectedFilter] = useState<string>("Recent");

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
  };

  const filterExercises = (pastExercises: any[]) => {
    switch (selectedFilter) {
      case "Recent":
        return pastExercises;

      case "Completed":
        return pastExercises.filter(
          (exercise) => exercise.status === "COMPLETED"
        );

      case "Incomplete":
        return pastExercises.filter(
          (exercise) => exercise.status === "INCOMPLETE"
        );

      default:
        return pastExercises;
    }
  };

  const filteredExercises = pastExercises ? filterExercises(pastExercises) : [];

  // Effect to initialize Hotjar for tracking
  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;
    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_history");
    }
  }, []);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const response = await getExerciseHistory();
        console.log("getExerciseHistory response:", response);
        if (response && response.results) {
          setPastExercises(response.results);
        }
      } catch (err: any) {
        console.error("getExerciseHistory error:", err.message || err);
      }
    };

    fetchExercises();
  }, [setPastExercises]);

  return (
    <div className={cx("student-dashboard-container content")}>
      <div className={cx("student-dashboard-filter-container")}>
        <div className={cx("student-dashboard-title")}>Exercise History</div>
        <div className={cx("student-filter-btn-container")}>
          {["Recent", "Completed", "Incomplete"].map((filter) => (
            <div
              key={filter}
              className={cx(
                selectedFilter === filter
                  ? "student-dashboard-selected-button"
                  : "student-dashboard-unselected-button"
              )}
              onClick={() => handleFilterClick(filter)}
            >
              <div className={cx("student-dashboard-label")}>{filter}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={cx("student-card-rows")}>
        {filteredExercises.length > 0 ? (
          filteredExercises.map((exercise) => (
            <DashboardExerciseCard
              key={exercise.exercise_id}
              exercise={exercise}
            />
          ))
        ) : (
          <div className={cx("student-card-noexercises")}>
            <img src={noExercisesIcon} alt="No Exercises" />
            <p className={cx("desc grey")}>No exercises found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardHistory;
