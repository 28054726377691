import { apiCall } from "utils/ApiUtils";
import { InputS3Url } from "store/student/useStudentExerciseStore";

const BASE_URL = `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v0.1`;

/* ===================== AUTHENTICATION ===================== */

export const login = (email: string, password: string) => {
  const url = `${BASE_URL}/auth/login`;
  const body = { email, password };
  return apiCall(url, "POST", body);
};

export const forgetPassword = (email: string) => {
  const url = `${BASE_URL}/student/forget-password`;
  const body = { email };
  return apiCall(url, "POST", body);
};

export const changePassword = (
  currentPassword: string,
  newPassword: string
) => {
  const url = `${BASE_URL}/student/change-password`;
  const body = { currentPassword, newPassword };
  return apiCall(url, "POST", body, true);
};

/* ===================== STUDENT PROFILE ===================== */

export const getStudentProfile = () => {
  const url = `${BASE_URL}/student/profile`;
  return apiCall(url, "GET", {}, true);
};

export const changeParentEmail = (email: string) => {
  const url = `${BASE_URL}/student/change-parent-email`;
  const body = {
    email: email,
  };
  return apiCall(url, "POST", body, true);
};

/* ===================== EXERCISE MANAGEMENT ===================== */

export const getExerciseList = () => {
  const url = `${BASE_URL}/student/exercise`;
  return apiCall(url, "GET", {}, true);
};

export const getExerciseById = (exerciseId: string) => {
  const url = `${BASE_URL}/student/exercise?exercise_id=${exerciseId}`;
  return apiCall(url, "GET", {}, true);
};

export const getLatestExercise = () => {
  const url = `${BASE_URL}/student/exercise-newest`;
  return apiCall(url, "GET", {}, true);
};

export const getRecentExercises = () => {
  const url = `${BASE_URL}/student/exercise?status=INCOMPLETE,COMPLETED&sort=last_interaction_date&order=DESC`;
  return apiCall(url, "GET", {}, true);
};

export const getNewestExercises = () => {
  const curr_time = new Date().toISOString();
  const url = `${BASE_URL}/student/exercise-newest?start_date_ge=${curr_time}&end_date_le=${curr_time}`;
  return apiCall(url, "GET", {}, true);
};

export const getExerciseHistory = () => {
  const url = `${BASE_URL}/student/exercise-history`;
  return apiCall(url, "GET", {}, true);
};

export const getExercisesByStatus = (status: string) => {
  const curr_time = new Date().toISOString();
  const url = `${BASE_URL}/student/exercise?status=${status}&start_date_ge=${curr_time}&end_date_le=${curr_time}&sort=start_date&order=DESC`;
  return apiCall(url, "GET", {}, true);
};

export const getExercisesHistoryByStatus = (status: string) => {
  const url = `${BASE_URL}/student/exercise?status=${status}&sort=start_date&order=DESC`;
  return apiCall(url, "GET", {}, true);
};

export const startOrContinueExercise = (exerciseId: string) => {
  const url = `${BASE_URL}/student/exercise/${exerciseId}/start`;
  return apiCall(url, "POST", {}, true);
};

export const newPresignedUpload = (
  exerciseId: string,
  input_s3_url: InputS3Url
) => {
  const url = `${BASE_URL}/student/exercise/${exerciseId}/new-presigned-upload`;
  const body = {
    input_s3_url: input_s3_url,
  };

  return apiCall(url, "POST", body, true);
};

export const getNextPrompt = (
  exerciseId: string,
  interactionMediaId: string
) => {
  const url = `${BASE_URL}/student/exercise/${exerciseId}/next-prompt`;
  return apiCall(
    url,
    "POST",
    { interaction_media_id: interactionMediaId },
    true
  );
};

export const getExerciseChatHistory = async (exerciseId: string) => {
  const url = `${BASE_URL}/student/exercise/${exerciseId}/chat-history`;
  const response = await apiCall(url, "GET", {}, true);

  return response.results.logEntries
    .filter(
      (entry: any) =>
        entry.entry.unionResult.dmResult?.textInUtf8 ||
        entry.entry.unionResult.asrResult?.textInUtf8
    )
    .map((entry: any) => ({
      id: entry.entry.moduleChoice,
      message:
        entry.entry.unionResult.dmResult?.textInUtf8 ||
        entry.entry.unionResult.asrResult?.textInUtf8,
    }));
};
