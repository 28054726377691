import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./MicDetectedDevices.scss";
import studentMicIcon from "../../../assets/studentMicIcon.svg";
import studentRecordingIcon from "../../../assets/studentRecordMic.svg";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";
import MicRecording from "../micRecording/MicRecording";
import ModalMicIdentifyButtons from "../modalMicIdentifyButtons/ModalMicIdentifyButtons";
import useMicPermissionStore from "store/student/checkMicPermissionStore";

const MicDetectedDevices = () => {
  const cx = classNames.bind(styles);
  const {
    microphones,
    speakers,
    selectedMic,
    selectedMicName,
    selectedSpeaker,
    setMicrophones,
    setSpeakers,
    setSelectedMic,
    setSelectedSpeaker,
  } = useMicPermissionStore();

  const [showModal, setShowModal] = React.useState<boolean>(true);
  const [micTestStage, setMicTestStage] = React.useState<
    "default" | "recording" | "playback"
  >("default");

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const isRecordButtonDisabled = isSafari
    ? !selectedMic
    : !selectedMic || !selectedSpeaker;

  const handleMicChange = async (event: SelectChangeEvent<string>) => {
    const selectedMicValue = event.target.value;
    const micExists = microphones.some(
      (mic) => mic.deviceId === selectedMicValue
    );

    if (micExists || selectedMicValue === "") {
      await setSelectedMic(selectedMicValue);
    } else {
      await setSelectedMic("");
    }
  };

  const handleSpeakerChange = (event: SelectChangeEvent<string>) => {
    const selectedSpeakerValue = event.target.value;
    const speakerExists = speakers.some(
      (speaker) => speaker.deviceId === selectedSpeakerValue
    );

    if (speakerExists || selectedSpeakerValue === "") {
      setSelectedSpeaker(selectedSpeakerValue);
    } else {
      setSelectedSpeaker("");
    }
  };

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const mics = devices.filter((device) => device.kind === "audioinput");
        const speakers = devices.filter(
          (device) => device.kind === "audiooutput"
        );

        setMicrophones(mics);
        setSpeakers(speakers);
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    };

    fetchDevices();
  }, [setMicrophones, setSpeakers]);

  useEffect(() => {
    const originalConsoleError = console.error;
    console.error = (...args) => {
      if (
        args[0] &&
        args[0].includes("MUI: You have provided an out-of-range value")
      ) {
        return;
      }
      originalConsoleError(...args);
    };

    return () => {
      console.error = originalConsoleError;
    };
  }, []);

  return (
    <>
      <div className={cx("st-detected-devices-container content")}>
        {showModal && <ModalMicIdentifyButtons setShowModal={setShowModal} />}

        <div className={cx("st-detected-devices-contents")}>
          <img
            className={cx("st-detected-devices-icon")}
            src={studentMicIcon}
            alt="student-detected-devices-icon"
          />
          {micTestStage === "recording" ? (
            <>
              <div className={cx("st-mic-test-recording-subtitle-mic")}>
                Your mic is {selectedMicName}
              </div>
              {!isSafari && (
                <div className={cx("st-mic-test-recording-subtitle-speaker")}>
                  Your speaker is{" "}
                  {selectedSpeaker
                    ? speakers.find((s) => s.deviceId === selectedSpeaker)
                        ?.label || "Unknown Speaker"
                    : "not selected"}
                </div>
              )}
            </>
          ) : (
            <>
              <div className={cx("st-detected-devices-title")}>
                Test Your Microphone
              </div>
              <div className={cx("st-detected-devices-subtitle")}>
                Your microphone is
              </div>
              <Select
                sx={{
                  marginTop: "0.75rem",
                  width: {
                    xs: "100%",
                    sm: "30.6875rem",
                  },
                  height: "4.5rem",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0.1875rem 0.1875rem 0.1875rem #0000000A",
                  border: "0.0625rem solid #7F93B22C",
                  borderRadius: "2.25rem",
                  opacity: 1,
                  paddingLeft: "2rem",
                  paddingTop: "1.5rem",
                  paddingBottom: "1.5rem",
                  font: "normal normal 600 1.25rem/1.875rem IBM Plex Serif",
                  color: "$paragraph-ghoul-grey",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                labelId="microphone-select-label"
                id="microphone"
                value={
                  microphones.some((mic) => mic.deviceId === selectedMic)
                    ? selectedMic
                    : ""
                }
                onChange={handleMicChange}
              >
                {microphones.map((mic) => (
                  <MenuItem
                    sx={{
                      font: "normal normal 600 1.25rem/1.875rem IBM Plex Serif",
                      color: "$paragraph-ghoul-grey",
                      paddingLeft: "2rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    value={mic.deviceId}
                    key={mic.deviceId}
                  >
                    {mic.label || mic.deviceId}
                  </MenuItem>
                ))}
              </Select>

              {!isSafari && (
                <>
                  <div className={cx("st-detected-devices-subtitle")}>
                    Your speaker is
                  </div>
                  <Select
                    sx={{
                      marginTop: "0.75rem",
                      width: {
                        xs: "100%",
                        sm: "30.6875rem",
                      },
                      height: "4.5rem",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "0.1875rem 0.1875rem 0.1875rem #0000000A",
                      border: "0.0625rem solid #7F93B22C",
                      borderRadius: "2.25rem",
                      opacity: 1,
                      paddingLeft: "2rem",
                      paddingTop: "1.5rem",
                      paddingBottom: "1.5rem",
                      font: "normal normal 600 1.25rem/1.875rem IBM Plex Serif",
                      color: "$paragraph-ghoul-grey",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    labelId="speaker-select-label"
                    id="speakers"
                    value={
                      speakers.some(
                        (speaker) => speaker.deviceId === selectedSpeaker
                      )
                        ? selectedSpeaker
                        : ""
                    }
                    onChange={handleSpeakerChange}
                  >
                    {speakers.map((speaker) => (
                      <MenuItem
                        sx={{
                          font: "normal normal 600 1.25rem/1.875rem IBM Plex Serif",
                          color: "$paragraph-ghoul-grey",
                          paddingLeft: "2rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        key={speaker.deviceId}
                        value={speaker.deviceId}
                      >
                        {speaker.label || speaker.deviceId}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </>
          )}
        </div>

        {micTestStage === "default" && (
          <div
            className={cx(
              "st-detected-devices-rectangle-container-start-recording"
            )}
          >
            <div className={cx("st-detected-devices-start-recording-label")}>
              Press the microphone button to start recording.
            </div>
            <img
              className={cx(
                "st-detected-devices-record-mic-icon",
                isRecordButtonDisabled ? "disabled" : ""
              )}
              src={studentRecordingIcon}
              alt="student-detected-devices-icon"
              onClick={() => {
                if (!isRecordButtonDisabled) {
                  setMicTestStage("recording");
                }
              }}
              style={{
                cursor: isRecordButtonDisabled ? "not-allowed" : "pointer",
                opacity: isRecordButtonDisabled ? 0.5 : 1,
              }}
            />
          </div>
        )}

        {micTestStage === "recording" && <MicRecording />}
      </div>
    </>
  );
};

export default MicDetectedDevices;
