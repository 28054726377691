import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./ModalExerciseNoResponse.scss";
import circularClose from "assets/modalCloseIconCircular.svg";
import nonSmiley from "assets/nonSmiley.svg";
import { useNavigate } from "react-router-dom";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";

interface ModalExerciseNoResponseProps {
  onClose: () => void;
}

const ModalExerciseNoResponse = ({ onClose }: ModalExerciseNoResponseProps) => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const { setShowModal } = useStudentExerciseStore();

  const handleRefresh = () => {
    window.location.reload();
    setShowModal(null);
  };

  useEffect(() => {
    const timer = setTimeout(
      () => {
        navigate("/home");
      },
      5 * 60 * 1000
    );
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={cx(
        "noresponse-popup-overlay ten-min-no-response-overlay modal-overlay"
      )}
    >
      <div className={cx("noresponse-popup-container ten-min-no-response")}>
        <img
          className={cx("noresponse-popup-close-btn")}
          src={circularClose}
          alt="circular-close"
          onClick={onClose}
        />
        <img className={cx("")} src={nonSmiley} alt="Oops" />
        <h1>Hmm… No response</h1>
        <div className={cx("noresponse-popup-message")}>
          <p>
            Looks like you are taking a break. If there is no response from your
            side, it will take you back to the home page automatically after a
            while.
          </p>
        </div>
        <button onClick={handleRefresh} className={cx("continuebtn")}>
          Continue to Welcome Screen
        </button>
      </div>
    </div>
  );
};

export default ModalExerciseNoResponse;
