import React from "react";
import classNames from "classnames/bind";
import styles from "./ModalEmailVerification.scss";
import studentMailIcon from "../../../assets/studentMailIcon.svg";
import modalCloseIcon from "../../../assets/modalCloseIconCircular.svg";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";

const ModalEmailVerification = ({ onClose }: { onClose: () => void }) => {
  const cx = classNames.bind(styles);
  const { parentsConfirmEmail, setParentsConfirmEmail, setShowModal } =
    useStudentSetupStore();

  const handleCloseIconClick = async () => {
    localStorage.setItem("parents-email-input", "");
    await localStorage.setItem("parent-email-pending-verification", "true");
    setShowModal(false);
    setParentsConfirmEmail("");
    onClose();
  };

  return (
    <div className={cx("modal-overlay")}>
      <div className={cx("modal-email")}>
        <img
          onClick={handleCloseIconClick}
          className={cx("close-icon-circular")}
          src={modalCloseIcon}
          alt="student-parent-email-success-modal-close-icon"
        />
        <div className={cx("parent-email-verification-success-icon-label")}>
          <img
            className={cx("email-verification-success-icon")}
            src={studentMailIcon}
            alt="student-parent-email-success-email-icon"
          />
          <div className={cx("parent-email-verification-label")}>
            Email Verification
          </div>
          <div className={cx("parent-email-verification-subtitle")}>
            Email verification instructions have been sent to your parent's
            email which is
            <span className={cx("highlight")}> {parentsConfirmEmail}</span>
            . Please check it and verify with the help of your parents.
            <br />
            <br />
            Try again after a while if you didn't receive the email.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEmailVerification;
