import React from "react";
import classNames from "classnames/bind";
import styles from "./ModalMicAccess.scss";
import circularClose from "assets/studentCircularClose.svg";
import oopsSadmood from "assets/oopsSadmood.svg";

interface ModalMicAccessProps {
  onClose: () => void;
}

const ModalMicAccess = ({ onClose }: ModalMicAccessProps) => {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("micaccess-modal mic-permission-denied modal-overlay")}>
      <div className={cx("micaccess-modal-container")}>
        <img
          className={cx("micaccess-modal-close-btn")}
          src={circularClose}
          alt="circular-close"
          onClick={onClose}
        />

        <img className={cx("")} src={oopsSadmood} alt="Oops Sad Mood" />

        <h1>Oops…We can’t access your mic</h1>

        <p>To use the microphone, we require your permission.</p>
        <p>
          Please ask your parents for permission to enable microphone access so
          you can use this feature.
        </p>

        <button onClick={onClose} className={cx("continuebtn")}>
          Continue to Exercise
        </button>
      </div>
    </div>
  );
};

export default ModalMicAccess;
