import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { useNavigate } from "react-router-dom";
import { useGetExerciseImageS3Url } from "hooks/useGetExerciseImageS3Url";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";
import useMicPermissionStore from "store/student/checkMicPermissionStore";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";
import ExerciseStartExchange from "components/student/exerciseStartExchange/ExerciseStartExchange";
import styles from "./StudentExerciseScreen.scss";
import ExerciseInitialMessage from "components/student/exerciseInitialMessage/ExerciseInitialMessage";
import ModalExerciseInstructions from "components/student/modalExerciseInstructions/ModalExerciseInstructions";
import ModalExerciseNoResponse from "components/student/modalExerciseNoResponse/ModalExerciseNoResponse";
import ModalMicAccess from "components/student/modalMicAccess/ModalMicAccess";
import ModalExerciseIncomplete from "components/student/modalExerciseIncomplete/ModalExerciseIncomplete";
import ModalInactiveMic from "components/student/modalInactiveMic/ModalInactiveMic";
import ModalExerciseErrorLoading from "components/student/modalExerciseErrorLoading/ModalExerciseErrorLoading";
import ModalExerciseComplete from "components/student/modalExerciseComplete/ModalExerciseComplete";
import { startOrContinueExercise } from "services/api/studentApi";
import ExerciseChatHistory from "components/student/exerciseChatHistory/ExerciseChatHistory";
import useInactivityTimeout from "hooks/useInactivityTimeout";
import ModalInterruptPlayback from "components/student/modalInterruptPlayback/ModalInterruptPlayback";

const StudentExerciseScreen = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const { selectedExercise } = useStudentExerciseStore();
  const { checkMicPermission, setIsRecording } = useMicPermissionStore();
  const {
    audioBlobUrl,
    showModal,
    isPendingAiResponse,
    hasStarted,
    hasCompletedExercise,
    setAudioRef,
    setAudioBlob,
    setAudioBlobUrl,
    setShowMicInstruction,
    setShowModal,
    setShowChatHistory,
    setIsAiAudioPlaying,
    setIsPendingAiResponse,
    setInputS3Url,
    setInteractionMediaId,
    setHasStarted,
    setHasCompletedExercise,
  } = useStudentExerciseStore();

  const selectedExerciseId = selectedExercise?.exercise_id || "";
  const exerciseImageS3Url = useGetExerciseImageS3Url(selectedExerciseId);

  const [micBlinking, setMicBlinking] = useState(false);

  const storeAudioBlob = (url: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((audioBlob) => {
        setAudioBlob(audioBlob);
        setIsPendingAiResponse(false);
        setIsAiAudioPlaying(true);

        const newAudioUrl = URL.createObjectURL(audioBlob);
        setAudioBlobUrl(newAudioUrl);
      })
      .catch((error) => {
        console.error("Error fetching audio:", error);
      });
  };

  const handleGoToHome = () => {
    setIsRecording(false);
    if (hasStarted && !hasCompletedExercise) {
      setShowModal("incomplete");
      setIsAiAudioPlaying(false);
    } else {
      navigate("/home");
    }
  };

  const handleCloseModal = () => {
    setShowModal(null);
  };

  const handleStartOrContinueExercise = async () => {
    setIsPendingAiResponse(true);
    setShowModal(null);
    setHasStarted(true);

    try {
      const response = await startOrContinueExercise(selectedExerciseId);
      if (response) {
        console.log("startOrContinueExercise response:", response);
        const { prompt, input_s3_url, interaction_media_id } = response;
        const audioUrl = prompt.prompt.soundFileUrl;
        setAudioRef({ current: new Audio(audioUrl) });
        setInteractionMediaId(interaction_media_id);
        setInputS3Url(input_s3_url);
        storeAudioBlob(audioUrl);
      }
    } catch (error) {
      console.error("handleStartOrContinueExercise error:", error);
    }
  };

  const renderModal = () => {
    switch (showModal) {
      case "instructions":
        return (
          <ModalExerciseInstructions
            handleStartBtn={handleStartOrContinueExercise}
            onClose={handleCloseModal}
          />
        );
      case "timeout":
        return <ModalExerciseNoResponse onClose={handleCloseModal} />;
      case "micblocked":
        return <ModalMicAccess onClose={handleCloseModal} />;
      case "inactivemic":
        return (
          <ModalInactiveMic
            onClose={handleCloseModal}
            onCta={handleCloseModal}
            descType="exercise"
          />
        );
      case "loadingerror":
        return <ModalExerciseErrorLoading />;
      case "incomplete":
        return <ModalExerciseIncomplete onClose={handleCloseModal} />;
      case "interrupted":
        return <ModalInterruptPlayback />;
      case "complete":
        return <ModalExerciseComplete />;
      default:
        return null;
    }
  };

  const resetMicBlinking = () => {
    setMicBlinking(false);
  };

  useEffect(() => {
    checkMicPermission();
  }, [checkMicPermission]);

  useEffect(() => {
    if (!audioBlobUrl) {
      setHasStarted(false);
    }
    setIsAiAudioPlaying(false);
    setIsPendingAiResponse(false);
    setAudioRef({ current: null });
    setShowModal(null);
    setShowMicInstruction(false);

    // Hotjar tracking
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_information");
    } else {
      console.error("Hotjar environment variables are not set.");
    }

    return () => {
      setShowChatHistory(false);
      setHasCompletedExercise(false);
    };
  }, []);

  useEffect(() => {
    if (!isPendingAiResponse) {
      return;
    }
    const timeoutId = setTimeout(
      () => {
        setShowModal("loadingerror");
      },
      10 * 60 * 1000
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isPendingAiResponse]);

  useInactivityTimeout(
    () => {
      if (hasStarted && micBlinking === false && !showModal) {
        setMicBlinking(true);
      }
    },
    () => {
      if (hasStarted && !isPendingAiResponse) {
        setShowModal("inactivemic");
        setMicBlinking(false);
      } else if (!hasStarted) {
        setShowModal("timeout");
      }
    },
    5 * 60 * 1000,
    1
  );

  return (
    <div
      className={cx("student student-information-container")}
      onClick={resetMicBlinking}
      onKeyDown={resetMicBlinking}
    >
      <ExerciseChatHistory onGoToHome={handleGoToHome} />
      <div className="student-information-wrapper">
        {exerciseImageS3Url ? (
          <img
            className={cx("student-information-background-img")}
            src={exerciseImageS3Url}
            alt="background"
          />
        ) : (
          <p>Loading image...</p>
        )}

        {hasStarted ? (
          <ExerciseStartExchange micBlinking={micBlinking} />
        ) : (
          <ExerciseInitialMessage
            isFirstTime={
              selectedExercise?.status !== "COMPLETED" &&
              selectedExercise?.status !== "INCOMPLETE"
            }
            onCta={() => handleStartOrContinueExercise()}
          />
        )}
      </div>
      {renderModal()}
    </div>
  );
};

export default StudentExerciseScreen;
