import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useLoginState } from "store/useLoginStore";
import checkIsTokenExpired from "./useCheckIsTokenExpired";

export const useLogOut = () => {
  const navigate = useNavigate();
  const { clearLoginState } = useLoginState();

  const clearLocalStorage = () => {
    const keysToRemove = ["login", "student-setup", "student-exercise"];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    localStorage.setItem("login", JSON.stringify(null));
    localStorage.setItem("student-setup", JSON.stringify(null));
    localStorage.setItem("student-exercise", JSON.stringify(null));
    clearLoginState();
  };

  const timeOut = () => {
    toast.dismiss();
    toast.error("Error: Your session has timed out. Please re-login.");
    clearLocalStorage();
    navigate("/", { replace: true });
  };

  const doLogOut = () => {
    navigate("/", { replace: true });
    clearLocalStorage();
  };

  return { doLogOut, timeOut, checkIsTokenExpired };
};
