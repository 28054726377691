import React, { useEffect, useState } from "react";
import styles from "./checklist.module.css";

// Importing the validation rules
import {
  AT_LEAST_ONE_LOWERCASE_REGEX,
  AT_LEAST_ONE_UPPERCASE_REGEX,
  AT_LEAST_ONE_NUMBER_REGEX,
  MIN_12_CHAR,
} from "../../utils/ValidationRules";

interface Props {
  value: string | undefined;
  className?: string;
}

const rules = [
  {
    label: "At least 1 lowercase letter",
    pattern: AT_LEAST_ONE_LOWERCASE_REGEX,
    completed: false,
  },
  {
    label: "At least 1 uppercase letter",
    pattern: AT_LEAST_ONE_UPPERCASE_REGEX,
    completed: false,
  },
  {
    label: "At least 1 digit",
    pattern: AT_LEAST_ONE_NUMBER_REGEX,
    completed: false,
  },
  { label: "At least 12 characters", pattern: MIN_12_CHAR, completed: false },
];

const CheckList = (props: Props) => {
  const [rulesState, setRulesState] = useState(rules);

  useEffect(() => {
    const updatedRules = rulesState.map((rule) => ({
      ...rule,
      completed: props.value ? !!props.value.match(rule.pattern) : false,
    }));
    setRulesState(updatedRules);

    const allRulesFulfilled = updatedRules.every((rule) => rule.completed);
    localStorage.setItem(
      "regexRulesFulfilled",
      allRulesFulfilled ? "true" : "false"
    );
  }, [props.value]);

  return (
    <>
      <div className={styles.title}>Password must have</div>

      <div className={styles.wrapper}>
        {rulesState.map((rule, index) => {
          const cn = rule.completed ? styles.passed : "";
          return (
            <p key={index} className={cn}>
              {rule.label}
            </p>
          );
        })}
      </div>
    </>
  );
};

export default CheckList;
