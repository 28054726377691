import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./ModalExerciseErrorLoading.scss";
import oopsSadmood from "assets/oopsSadmood.svg";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";
import { useNavigate } from "react-router-dom";
import circularClose from "assets/studentCircularClose.svg";

const ModalExerciseErrorLoading = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const { setShowModal } = useStudentExerciseStore();

  useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        setShowModal(null);
        navigate("/home");
      },
      5 * 60 * 1000
    );
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={cx("refresh-modal modal-overlay")}>
      <div className={cx("refresh-modal-container")}>
        <img className={cx("")} src={oopsSadmood} alt="Oops" />
        <img
          className={cx("refresh-modal-close-btn")}
          src={circularClose}
          alt="circular-close"
        />
        <h1>We are sorry!</h1>
        <div
          className={cx("refresh-modal-message refresh-first-modal-message")}
        >
          <span>
            It looks like it's not working. Please contact your teacher. The
            page will go back to the home page in a few minutes.
          </span>
        </div>
      </div>
    </div>
  );
};

export default ModalExerciseErrorLoading;
