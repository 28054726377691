import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./StudentLoginScreen.scss";
import Spinner from "../../../shared/spinner/Spinner";
import { login, getStudentProfile } from "services/api/studentApi";
import { reactGAEvent } from "../../../shared/ReactGA";
import { initHotjar } from "../../../helpers/Hotjar";
import Hotjar from "@hotjar/browser";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";
import LoginForgotPin from "components/student/loginForgotPin/LoginForgotPin";
import { useLoginState } from "store/useLoginStore";
import { isValidEmail } from "utils/emailUtils";
import useMicPermissionStore from "store/student/checkMicPermissionStore";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";

const StudentLoginScreen = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const { token, role, setToken, setRole } = useLoginState();
  const { micPermissionState, setMicPermissionState } = useMicPermissionStore();
  const { setStudentProfile } = useStudentSetupStore();

  const [email, setEmail] = useState("");
  const [pins, setPins] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState<{ message: string | null; flag: boolean }>(
    { message: null, flag: false }
  );
  const [emailError, setEmailError] = useState<{ message: string | null, flag: boolean }>({ message: null, flag: false });
  const [pinError, setPinError] = useState<{ message: string | null, flag: boolean }>({ message: null, flag: false });
  const [loading, setLoading] = useState(false);
  const [isForgotPin, setIsForgotPin] = useState(false);

  const checkToken = token && role === "STUDENT";

  const handleForgotPinClick = () => setIsForgotPin(true);
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handlePinChange = (index: number, value: string) => {
    const updatedPins = [...pins];
    updatedPins[index] = value;
    setPins(updatedPins);

    if (value && index < 5) {
      document.getElementById(`pin${index + 1}`)?.focus();
    } else if (!value && index > 0) {
      document.getElementById(`pin${index - 1}`)?.focus();
    }
  };

  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === "Backspace" && !pins[index] && index > 0) {
      event.preventDefault();
      document.getElementById(`pin${index - 1}`)?.focus();
    }
    if (!/^[0-9]$/.test(event.key) && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const showError = (message: string) => {
    setPinError({ message, flag: true });
    setLoading(false);
  };

  const handleLoginButton = () => {
    setError({ message: null, flag: false });
    setEmailError({ message: null, flag: false });
    setPinError({ message: null, flag: false });

    const pin = pins.join("");

    if (!email && pin.length !== 6) {
      setEmailError({ message: "Email is required", flag: true });
      setPinError({ message: "PIN is required", flag: true });
      return;
    }

    if (!email) {
      setEmailError({ message: "Email is required", flag: true });
      return;
    }

    if (pin.length === 0 || pin.length !== 6) {
      setPinError({ message: "PIN is required", flag: true });
      return;
    }

    if (!isValidEmail(email)) {
      setEmailError({ message: "Invalid email credentials", flag: true });
      return;
    }

    reactGAEvent(
      "Login Page for Students",
      "Clicked into Login Button for Students"
    );
    handleClickLogin(email, pin);
  };

  const handleClickLogin = async (email: string, pin: string) => {
    setLoading(true);

    try {
      const response = await login(email, pin);

      if (response?.auth_token) {
        setToken(response.auth_token);
        setRole(response.payload.role);
        console.log("Login response:", response);
        await checkOnboardingStatus();
      } else {
        showError("Information provided is incorrect.");
      }
    } catch (error: any) {
      showError("Information provided is incorrect");
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  const checkOnboardingStatus = async () => {
    try {
      const response = await getStudentProfile();
      setStudentProfile(response);
      if (response.parent_email && micPermissionState === "granted") {
        navigate("/home");
      } else {
        navigate("/welcome");
      }
      console.log("getStudentProfile response:", response);
    } catch (error) {
      console.error("getStudentProfile error:", error);
      navigate("/welcome");
    }
  };

  useEffect(() => { }, [checkToken]);

  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;
    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_login");
    } else {
      console.error("Hotjar environment variables are not set.");
    }
  }, []);

  useEffect(() => {
    const checkMicPermissions = async () => {
      try {
        if (navigator.userAgent.indexOf("Firefox") > -1) {
          if (micPermissionState === null || micPermissionState === "prompt") {
            setMicPermissionState("prompt");
          }
        } else {
          const permissionStatus = await navigator.permissions.query({
            name: "microphone" as PermissionName,
          });
          setMicPermissionState(permissionStatus.state);
        }
      } catch (error) {
        console.error("Error checking microphone permissions:", error);
      }
    };

    checkMicPermissions();
  }, [micPermissionState, setMicPermissionState]);

  return (
    <>
      {loading && <Spinner />}
      <div
        className={cx("student st-login-screen screen", {
          "st-login-screen__disabled": loading,
        })}
      >
        {isForgotPin ? (
          <LoginForgotPin setIsForgotPin={setIsForgotPin} />
        ) : (
          <div className={cx("st-login-container")}>
            <div
              className={cx("st-rectangle", {
                "st-rectangle-validation":
                  error.flag ||
                  pins.some((pin) => pin.length === 0) ||
                  email.length === 0,
              })}
            >
              <div className={cx("st-login-contents-container")}>
                <div className={cx("title green")}>Login</div>
                <div className={cx("st-login-subtitle")}>
                  Please enter your email and PIN to login.
                </div>

                <div
                  className={cx("st-login-email-label", {
                    "st-login-email-label-validation": !email && error.flag,
                  })}
                >
                  Your Email
                </div>
                <input
                  className={cx("st-login-email-input input", {
                    "st-login-email-input-error-highlight":
                      emailError.flag
                  })}
                  type="text"
                  placeholder="Type your email here"
                  value={email}
                  onChange={handleEmailChange}
                />
                {/* {error.flag && !email && (
                  <div className={cx("st-login-email-input-validation")}>
                    Email is required
                  </div>
                )} */}

                {emailError.flag && emailError.message && (
                  <div className={cx("st-login-error-message")}>
                    {emailError.message}
                  </div>
                )}

                <div
                  className={cx("st-login-pin-label", {
                    "st-login-pin-label-validation": pins.some(
                      (pin) => pin.length === 0 && pinError.flag
                    ),
                  })}
                >
                  Enter PIN
                </div>

                <div className={cx("st-login-pin-container")}>
                  {pins.map((pin, index) => (
                    <input
                      key={index}
                      id={`pin${index}`}
                      className={cx("st-login-circular-pin input", {
                        "st-login-circular-pin-validation": !pin && pinError.flag,
                      })}
                      type="text"
                      pattern="[0-9]"
                      value={pin}
                      onFocus={(e) => e.target.select()}
                      onInput={(e) =>
                        handlePinChange(
                          index,
                          (e.target as HTMLInputElement).value.slice(0, 1)
                        )
                      }
                      onKeyDown={(e) => handleInputKeyDown(e, index)}
                    />
                  ))}
                </div>
                {pinError.flag && pinError.message && (
                  <div className={cx("st-login-error-message")}>
                    {pinError.message}
                  </div>
                )}
                <div className={cx("st-login-forgot-pin-label")}>
                  Forgot PIN?{" "}
                  <button
                    className={cx("st-login-forget-pin-link")}
                    onClick={handleForgotPinClick}
                  >
                    Click here
                  </button>
                </div>

                <div className={cx("st-login-btn")}>
                  <StudentButtonCTA
                    type="primary"
                    text="Start"
                    onClick={handleLoginButton}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StudentLoginScreen;


