import React, { useEffect, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./ExerciseChatHistory.scss";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";
import { getExerciseChatHistory } from "services/api/studentApi";
import homeIcon from "assets/Home.svg";
import historyIcon from "assets/History.svg";
import closeIcon from "assets/Chat History Close.svg";
import noChatHistoryIcon from "assets/no-chat-history.svg";

interface ExerciseChatHistoryProps {
  onGoToHome: () => void;
}

const ExerciseChatHistory = ({ onGoToHome }: ExerciseChatHistoryProps) => {
  const cx = classNames.bind(styles);
  const {
    selectedExercise,
    chatHistory,
    showChatHistory,
    setShowChatHistory,
    setChatHistory,
  } = useStudentExerciseStore();

  const chatHistoryEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showChatHistory && selectedExercise?.exercise_id) {
      const fetchChatHistory = async () => {
        try {
          const response = await getExerciseChatHistory(
            selectedExercise?.exercise_id
          );
          setChatHistory(response);
          console.log("getExerciseChatHistory GET:", response);
        } catch (error) {
          setChatHistory([]);
          console.error("getExerciseChatHistory GET error:", error);
        }
      };

      fetchChatHistory();
    }
  }, [showChatHistory, selectedExercise, setChatHistory]);

  // Scroll to the bottom of the chat history when the chat history changes
  useEffect(() => {
    if (showChatHistory && chatHistoryEndRef.current) {
      chatHistoryEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory, showChatHistory]);

  return (
    <>
      <div className={cx("student-information-background-chat")}>
        <div
          className={cx("icons-container", {
            "icons-container-active": showChatHistory,
          })}
        >
          <img
            src={homeIcon}
            alt="home-icon"
            className={cx("icon")}
            onClick={onGoToHome}
          />
          <img
            src={showChatHistory ? closeIcon : historyIcon}
            alt="toggle-icon"
            className={cx("icon")}
            onClick={() => setShowChatHistory(true)}
          />
        </div>
      </div>

      {showChatHistory && (
        <div
          className={cx("chat-history-overlay")}
          onClick={() => setShowChatHistory(false)}
        >
          <div className={cx("chat-history-container")}>
            <div
              className={cx("chat-history-popout")}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={cx("icons-container-modal")}>
                <img
                  src={homeIcon}
                  alt="home-icon"
                  className={cx("icon")}
                  onClick={onGoToHome}
                />
                <img
                  src={closeIcon}
                  alt="close-icon"
                  className={cx("icon")}
                  onClick={() => setShowChatHistory(false)}
                />
              </div>

              {/* Chat feed UI */}
              <div
                className={cx(
                  `custom-chat-feed ${chatHistory.length === 0 ? "nochat" : ""}`
                )}
              >
                <div className="custom-chat-feed-title">
                  <div>Conversation History</div>
                  <hr className={cx("custom-chat-feed-divider")} />
                </div>
                {chatHistory.length === 0 ? (
                  <>
                    <div className={cx("custom-chat-feed-nochat")}>
                      <img src={noChatHistoryIcon} alt="No Chat History" />
                      Chat history is empty.
                    </div>
                    <div></div>
                  </>
                ) : (
                  chatHistory.map((msg, index) => (
                    <div
                      key={index}
                      className={cx("chat-bubble-container", {
                        "chat-bubble-left": msg.id === 0,
                        "chat-bubble-right": msg.id === 1,
                      })}
                    >
                      <div
                        className={cx("chat-bubble", {
                          "chat-bubble-id-0": msg.id === 0,
                          "chat-bubble-id-1": msg.id === 1,
                        })}
                      >
                        {msg.message}
                      </div>
                    </div>
                  ))
                )}
                <div ref={chatHistoryEndRef} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExerciseChatHistory;
