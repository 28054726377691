import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./ForgetPw.scss";
import systemOwnerForgetPwBackground from "../../../assets/systemOwnerLoginBackground.png";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { forgetPassword } from "config/WebServiceConfig";

const ForgetPw = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const [email, setChangeEmail] = useState("");
  const [jSONFlagError, setJSONFlagError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleEmailChange = (event: any) => {
    setChangeEmail(event.target.value);
  };

  const postForgetPassword = async (email: string) => {
    try {
      await forgetPassword({ email });
      localStorage.setItem("reset-password", "true");
      navigate("/admin/system-owner-login");
    } catch (err: any) {
      setJSONFlagError(true);
      setErrorMsg(err.message || "An error occurred. Please try again later.");
      console.log("error", err);
    }
  };

  return (
    <div className={cx("system-owner-forget-pw-container")}>
      <img
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100vh",
        }}
        src={systemOwnerForgetPwBackground}
        alt="system-owner-forget-pw-bg"
      />

      <div className={cx("so-forget-pw-rectangle-container")}>
        <div className={cx("so-forget-pw-rectangle")}>
          <div className={cx("so-forget-pw-login-container")}>
            <div className={cx("so-forget-pw-login-title")}>
              Forget Password
            </div>

            <div className={cx("so-forget-pw-login-subtitle")}>
              Enter the email address you used when you joined and we’ll <br />
              send you instructions to reset your password.
            </div>

            <Form className={cx("so-forget-pw-form-inputs")}>
              <FormGroup floating>
                <Input
                  className={cx("so-forget-pw-email-input")}
                  id="so-email-input"
                  name="email"
                  placeholder="Your Email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <Label
                  className={cx("so-forget-pw-email-label")}
                  for="so-email-label"
                >
                  Your Email
                </Label>
              </FormGroup>
            </Form>
            {jSONFlagError ? (
              <div className={cx("so-forget-pw-field-validation")}>
                {errorMsg}
              </div>
            ) : null}
            <div className={cx("so-forget-pw-btn-container")}>
              <button
                className={cx("so-forget-pw-btn-back")}
                onClick={() => navigate("/admin/system-owner-login")}
              >
                Back
              </button>
              <button
                className={cx("so-forget-pw-btn-send")}
                onClick={() => postForgetPassword(email)}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPw;
