import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./ModalInactiveMic.scss";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";
import sadEmoJi from "assets/sad-emoji.svg";
import modalCloseIcon from "../../../assets/modalCloseIconCircular.svg";
import { useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

interface ModalInactiveMicProps {
  onClose: () => void;
  onCta: () => void;
  descType?: "setup" | "exercise";
}

const ModalInactiveMic = ({
  onClose,
  onCta,
  descType = "setup",
}: ModalInactiveMicProps) => {
  const navigate = useNavigate();

  const descriptionMap = {
    exercise:
      "Please share your response by clicking on the microphone icon and then click the send button to submit your answer. If you're having trouble with your microphone, please make sure to check it's settings.",
    setup:
      "It seems like there's an issue with your microphone. Please ensure it's correctly set up or try restarting your device.",
  };

  useEffect(() => {
    let timeoutId: any;

    if (descType === "exercise") {
      timeoutId = setTimeout(
        () => {
          navigate("/home");
        },
        5 * 60 * 1000
      );
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [descType]);

  return (
    <div className={cx("modal-inactive-mic-overlay modal-overlay")}>
      <div className={cx("modal-inactive-mic-container")}>
        <img
          src={modalCloseIcon}
          className={cx("modal-inactive-mic-close-btn")}
          onClick={onClose}
          alt="close-modal"
        />
        <div className={cx("modal-inactive-mic-img")}>
          <img src={sadEmoJi} alt="Sad Emoji" className={cx("sad-emoji")} />
        </div>
        <div className={cx("modal-inactive-mic-title")}>
          Hmm... Looks like no response
        </div>
        <div className={cx("modal-inactive-mic-desc")}>
          {descriptionMap[descType]}
        </div>
        <div className={cx("modal-inactive-mic-cta")}>
          <StudentButtonCTA type="primary" text="Try Again" onClick={onCta} />
        </div>
      </div>
    </div>
  );
};

export default ModalInactiveMic;
