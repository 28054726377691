import React from "react";
import styles from "./ModalInterruptPlayback.scss";
import classNames from "classnames";
import sadFace from "assets/oopsSadmood.svg";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";

const ModalInterruptPlayback = () => {
  const cx = classNames.bind(styles);

  const { setIsFirstInterruption, setIsAiAudioPlaying, setShowModal } =
    useStudentExerciseStore();

  const handleNoBtn = () => {
    setIsFirstInterruption(false);
  };

  const handleYesBtn = () => {
    setShowModal(null);
    setIsFirstInterruption(true);
    setIsAiAudioPlaying(true);
  };

  return (
    <div className={cx("interrupt-modal modal-overlay")}>
      <div className={cx("interrupt-modal-container")}>
        <img src={sadFace} alt="Sad Emoji" />
        <h1>Oops...</h1>
        <p>
          It looks like you want to talk! Make sure you listen to everything
          first before clicking the mic button.
        </p>
        <p>Did you click the mic button by mistake?</p>
        <div className={cx("modal-cta")}>
          <StudentButtonCTA text="No" type="secondary" onClick={handleNoBtn} />
          <StudentButtonCTA text="Yes" type="primary" onClick={handleYesBtn} />
        </div>
      </div>
    </div>
  );
};

export default ModalInterruptPlayback;
