import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Exercise } from "store/student/useStudentExerciseStore";
import classNames from "classnames/bind";
import styles from "./DashboardExerciseCard.scss";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";

interface DashboardExerciseCardProps {
  exercise: Exercise;
}

const DashboardExerciseCard = ({ exercise }: DashboardExerciseCardProps) => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();

  const { setSelectedExercise } = useStudentExerciseStore();
  const [currentStatus, setCurrentStatus] = useState<string>("open");
  const [showButton, setShowButton] = useState<boolean>(true);
  const [buttonText, setButtonText] = useState<string>("Start");

  const handleCardClick = () => {
    setSelectedExercise(exercise);
    navigate("/exercise");
  };

  useEffect(() => {
    switch (exercise.status) {
      case "NOT_STARTED":
      case "OPEN":
        setCurrentStatus("open");
        setButtonText("Start");
        break;
      case "INCOMPLETE":
        setCurrentStatus("incomplete");
        setButtonText("Continue");
        break;
      case "COMPLETED":
        setCurrentStatus("completed");
        setShowButton(false);
        break;
      default:
        return;
    }
  }, [exercise]);

  return (
    <div className={cx("student-home-cards-rect")} key={exercise.exercise_id}>
      <div className="debug">
        <div data-testid="start-date">{exercise.start_date}</div>
        <div data-testid="end-date">{exercise.end_date}</div>
        <div data-testid="image-id">{exercise.image_id}</div>
      </div>
      <img
        className={cx("student-home-image")}
        src={exercise.s3}
        alt={exercise.task || "Exercise image"}
      />
      <div className={cx("home-card-label-container")}>
        <div>Last Attempt Date</div>
        <div>Status</div>
      </div>
      <div className={cx("home-card-data-container")}>
        <div>
          {exercise.last_interaction_date
            ? new Date(exercise.last_interaction_date).toLocaleDateString(
                "en-GB"
              )
            : "-"}
        </div>
        <div className={cx(`home-card-status-data-${currentStatus}`)}>
          {currentStatus}
        </div>
      </div>
      <div className="student-home-card-btn">
        {showButton && (
          <StudentButtonCTA
            type="primary"
            text={buttonText}
            onClick={handleCardClick}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardExerciseCard;
