import React, { useEffect, useState, useRef } from "react";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";
import { getNextPrompt, newPresignedUpload } from "services/api/studentApi";
import AudioBar from "shared/audioBar/AudioBar";
import { useMediaStreamStore } from "store/student/useMediaStreamStore";
import styles from "./ExerciseStartExchange.scss";
import classNames from "classnames/bind";
import repeatIcon from "assets/Repeat.svg";
import micIcon from "assets/Mic.svg";
import sendIcon from "assets/recordingSend.svg";
import robotIcon from "assets/robot.svg";
import useMicPermissionStore from "store/student/checkMicPermissionStore";
import instructionArwLeft from "assets/left-arrow.svg";
import instructionArwRight from "assets/right-arrow.svg";
import { useAudioAnalyserStore } from "store/student/useAudioAnalyserStore";
import micArw from "assets/micarrow.svg";
import AiAudioVisualizer from "shared/AiAudioVisualizer/AiAudioVisualizer";

interface ExerciseStartExchangeProps {
  micBlinking: boolean;
}

const ExerciseStartExchange = ({ micBlinking }: ExerciseStartExchangeProps) => {
  const cx = classNames.bind(styles);

  const {
    audioRef,
    showMicInstruction,
    isFirstInterruption,
    selectedExercise,
    hasCompletedExercise,
    setAudioRef,
    setAudioBlob,
    setIsFirstInterruption,
    setShowModal,
    setIsAiAudioPlaying,
    setIsPendingAiResponse,
    setSelectedExercise,
    setHasCompletedExercise,
  } = useStudentExerciseStore();
  const { start, stop } = useMediaStreamStore();
  const { isRecording, setIsRecording } = useMicPermissionStore();
  const { isAiAudioPlaying, isPendingAiResponse, inputS3Url } =
    useStudentExerciseStore();
  const { checkMicPermission, micPermissionState } = useMicPermissionStore();

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isInstructionActive, setIsInstructionActive] = useState(false);
  const [hasShownMicInstruction, setHasShownMicInstruction] = useState(false);
  const [hasShownSendInstruction, setHasShownSendInstruction] = useState(false);

  const mimeType = MediaRecorder.isTypeSupported("audio/webm;codecs=opus")
    ? "audio/webm;codecs=opus"
    : MediaRecorder.isTypeSupported("audio/mp4")
      ? "audio/mp4"
      : "audio/ogg";

  const storeAudioBlob = (url: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((audioBlob) => {
        setAudioBlob(audioBlob);
        setIsPendingAiResponse(false);
        setIsAiAudioPlaying(true);
      })
      .catch((error) => {
        console.error("Error fetching audio:", error);
      });
  };

  const handleFirstRecording = () => {
    checkMicPermission();

    if (micPermissionState === "denied") {
      if (isAiAudioPlaying) {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      }
      setShowModal("micblocked");
      return;
    }

    if (isAiAudioPlaying && isFirstInterruption && !hasCompletedExercise) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      setShowModal("interrupted");
      setIsAiAudioPlaying(false);
    } else {
      handleStartRecording();
    }
  };

  const handleStartRecording = async () => {
    if (audioRef.current && audioRef.current instanceof HTMLAudioElement) {
      try {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      } catch (error) {
        console.error("Error pausing audio:", error);
      }
    } else {
      console.warn(
        "audioRef.current is not an HTMLAudioElement, cannot pause."
      );
    }

    checkMicPermission();

    if (micPermissionState === "denied") {
      if (isAiAudioPlaying) {
        if (audioRef.current && audioRef.current instanceof HTMLAudioElement) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      }
      setShowModal("micblocked");
      return;
    }

    if (hasCompletedExercise) {
      if (isAiAudioPlaying) {
      }
      setSelectedExercise(null);
      setShowModal("complete");
    } else {
      setShowModal(null);
      setHasShownMicInstruction(true);

      try {
        setIsRecording(true);
        start(true, false);

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        const audioContext = new AudioContext();
        const analyserNode = audioContext.createAnalyser();
        analyserNode.fftSize = 256;
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyserNode);

        useAudioAnalyserStore.setState({ analyser: analyserNode });

        mediaRecorderRef.current = new MediaRecorder(stream, {
          audioBitsPerSecond: 128000,
          mimeType: mimeType,
        });

        audioChunksRef.current = [];

        mediaRecorderRef.current.ondataavailable = (event: any) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.start();
      } catch (error) {
        console.error("Error accessing the microphone:", error);
        setShowModal("micpermissionerror");
      }
    }
  };

  const handleStopAndSendRecording = async () => {
    setIsRecording(false);
    setIsAiAudioPlaying(false);
    setIsPendingAiResponse(true);
    if (!mediaRecorderRef.current) {
      console.error("No media recorder available.");
      return;
    }

    mediaRecorderRef.current.stop();

    try {
      setTimeout(() => {
        const recordingAudioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        console.log(recordingAudioBlob);

        if (recordingAudioBlob.size === 0) {
          setIsPendingAiResponse(false);
          setIsRecording(false);
          console.error(
            "Audio Blob is empty. Ensure data is captured properly."
          );
          return;
        }
        uploadAudio(recordingAudioBlob);
      }, 1000);
    } catch (error) {
      console.error("Error in handleStopAndSendRecording:", error);
    } finally {
      setIsRecording(false);
      stop();
    }
  };

  const uploadAudio = async (recordingAudioBlob: Blob) => {
    if (!inputS3Url || !selectedExercise) {
      return;
    }
    try {
      const presignedResponse = await newPresignedUpload(
        selectedExercise.exercise_id,
        inputS3Url
      );

      if (!presignedResponse?.input_s3_url) {
        console.error(
          "Invalid response from presigned upload:",
          presignedResponse
        );
        return;
      }

      const { url, fields } = presignedResponse.input_s3_url;
      const formData = new FormData();
      formData.append("key", fields.key);
      formData.append("AWSAccessKeyId", fields.AWSAccessKeyId);
      formData.append("policy", fields.policy);
      formData.append("signature", fields.signature);
      if (process.env.REACT_APP_SERVER !== "internal") {
        formData.append("x-amz-security-token", fields["x-amz-security-token"]);
      }
      formData.append("file", recordingAudioBlob, "audio.webm");

      const uploadResponse = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (!uploadResponse.ok) {
        throw new Error(`Failed to upload audio: ${uploadResponse.status}`);
      }

      handleGetNextPrompt(presignedResponse.interaction_media_id);
    } catch (error) {
      console.error("Error during audio upload:", error);
      setShowModal("uploaderror");
    }
  };

  const handleGetNextPrompt = async (interactionMediaId: string) => {
    if (!selectedExercise) {
      return;
    }

    try {
      setIsPendingAiResponse(true);
      setIsAiAudioPlaying(false);
      const promptResponse = await getNextPrompt(
        selectedExercise.exercise_id,
        interactionMediaId
      );

      if (!promptResponse) {
        throw new Error("Empty response from next-prompt API");
      }

      const { response, end } = promptResponse;
      const audioUrl = response.soundFileUrl;

      const newAudio = new Audio(audioUrl);
      setAudioRef({ current: newAudio });
      storeAudioBlob(audioUrl);

      if (end) {
        setHasCompletedExercise(true);
      }

      console.log(promptResponse);
    } catch (error) {
      console.error("Error in handleGetNextPrompt:", error);
    }
  };

  const handleRepeatAiAudio = () => {
    if (!isAiAudioPlaying) {
      setIsAiAudioPlaying(true);
    }
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      audio
        .play()
        .catch((error) => console.error("Error playing audio:", error));
    }
  };

  const handleAiIconClick = () => {
    if (isAiAudioPlaying) {
      return;
    }
    if (!hasCompletedExercise) {
      setIsInstructionActive(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setIsInstructionActive(false);
      }, 6000);
    } else {
      setHasShownSendInstruction(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setHasShownSendInstruction(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (!isFirstInterruption && !isAiAudioPlaying) {
      handleStartRecording();
    }
  }, [isFirstInterruption]);

  useEffect(() => {
    setIsRecording(false);
    return () => {
      if (mediaRecorderRef.current?.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach((track: any) => {
          track.stop();
          track.enabled = false;
        });
        mediaRecorderRef.current = null;
      }
      setIsRecording(false);
      setIsFirstInterruption(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className={cx("student-information-aibox")}>
        {isRecording ? (
          <div className={cx("st-mic-test-recording-audio-container")}>
            <AudioBar height="33" type="white" />
            <div className={cx("stoprecording-icon")}>
              <img
                src={sendIcon}
                alt="Stop recording"
                onClick={handleStopAndSendRecording}
              />
            </div>
          </div>
        ) : (
          <>
            <div
              className={`student-information-aiexchange ${isPendingAiResponse ? "pendingaires" : ""} ${isAiAudioPlaying ? "playingaiaudio" : ""}`}
            >
              <div className="left">
                <div
                  className={`instructions-repeat ${isInstructionActive ? "show" : ""}`}
                >
                  <div className={"instruction-text-area"}>
                    Click the repeat button to hear again
                  </div>
                  <div className={"instruction-arrow-area"}>
                    <img src={instructionArwLeft} alt="Left arrow" />
                  </div>
                </div>
                <div
                  className={`student-information-aiexchange--repeaticon ${isInstructionActive ? "active" : ""}`}
                >
                  <img
                    src={repeatIcon}
                    alt="Repeat AI response icon"
                    onClick={handleRepeatAiAudio}
                  />
                </div>
              </div>

              <button
                className="student-information-aiexchange--aiicon button"
                onClick={handleAiIconClick}
              >
                <div className="pending">
                  <div className="pulse">
                    {isAiAudioPlaying ? (
                      <AiAudioVisualizer />
                    ) : (
                      <>
                        <img src={robotIcon} alt="AI robot icon" />
                      </>
                    )}
                  </div>
                  <div className="loading">
                    <div className="dot one"></div>
                    <div className="dot two"></div>
                    <div className="dot three"></div>
                    <div className="dot four"></div>
                  </div>
                </div>
              </button>

              <div className="right">
                {!isAiAudioPlaying &&
                  !hasShownMicInstruction &&
                  showMicInstruction && (
                    <>
                      <div className={`instructions-mic`}>
                        <div className={"instruction-text-area"}>
                          Click mic button to answer
                        </div>
                        <div className={"instruction-arrow-area"}>
                          <img src={micArw} alt="Right arrow" />
                        </div>
                      </div>
                    </>
                  )}
                <div
                  className={`instructions-record ${isInstructionActive ? "show" : ""}`}
                >
                  <div className={"instruction-text-area"}>
                    Click the mic button to answer
                  </div>
                  <div className={"instruction-arrow-area"}>
                    <img src={instructionArwRight} alt="Right arrow" />
                  </div>
                </div>
                <div
                  className={`instructions-send ${hasShownSendInstruction ? "show" : ""}`}
                >
                  <div className={"instruction-text-area"}>
                    Click the send button to finish
                  </div>
                  <div className={"instruction-arrow-area"}>
                    <img src={instructionArwRight} alt="Right arrow" />
                  </div>
                </div>
                <div
                  className={`student-information-aiexchange--recordicon ${isInstructionActive ? "active" : ""} ${micBlinking ? "blink" : ""}`}
                >
                  <img
                    src={hasCompletedExercise ? sendIcon : micIcon}
                    alt="Mic record icon"
                    onClick={
                      isFirstInterruption
                        ? handleFirstRecording
                        : handleStartRecording
                    }
                  />
                  <div
                    className={"circle"}
                    style={{ animationDelay: "-3s" }}
                  ></div>
                  <div
                    className={"circle"}
                    style={{ animationDelay: "-2s" }}
                  ></div>
                  <div
                    className={"circle"}
                    style={{ animationDelay: "-1s" }}
                  ></div>
                  <div
                    className={"circle"}
                    style={{ animationDelay: "0s" }}
                  ></div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ExerciseStartExchange;
