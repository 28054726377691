import React from "react";
import classNames from "classnames/bind";
import styles from "./ModalExerciseInstructions.scss";
import circularClose from "assets/modalCloseIconCircular.svg";
import micInfo from "assets/studentRecordMic.svg";
import repeatInfo from "assets/studentRepeatInfo.svg";
import sendInfo from "assets/studentSendInfo.svg";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";

const cx = classNames.bind(styles);

interface ModalExerciseInstructionsProps {
  handleStartBtn: () => void;
  onClose: () => void;
}

const ModalExerciseInstructions = ({
  handleStartBtn,
  onClose,
}: ModalExerciseInstructionsProps) => {
  return (
    <div className={cx("st-info-instructions-overlay modal-overlay")}>
      <div className={cx("st-info-instructions-panel")}>
        <img
          className={cx("st-info-instructions-close-btn")}
          src={circularClose}
          alt="circular-close"
          onClick={onClose}
        />
        <div className={cx("st-info-instructions-desc-container")}>
          <div className={cx("st-info-instructions-title")}>
            Cool! You're almost there!
          </div>
          <div className={cx("st-info-instructions-icon-set")}>
            <img
              className={cx("st-info-instructions-icon")}
              src={micInfo}
              alt="st-info-instructions-mic-info"
            />
            <div className={cx("st-info-instructions-label-set")}>
              <div className={cx("st-info-instructions-icon-title")}>
                Microphone
              </div>
              <div className={cx("st-info-instructions-icon-subtitle")}>
                Press the microphone button to provide your answer.
              </div>
            </div>
          </div>
          <div className={cx("st-info-instructions-icon-set")}>
            <img
              className={cx("st-info-instructions-icon")}
              src={repeatInfo}
              alt="st-info-instructions-repeat-info"
            />
            <div className={cx("st-info-instructions-label-set")}>
              <div className={cx("st-info-instructions-icon-title")}>
                Repeat
              </div>
              <div className={cx("st-info-instructions-icon-subtitle")}>
                Press the loudspeaker button to hear the question again.
              </div>
            </div>
          </div>
          <div className={cx("st-info-instructions-icon-set")}>
            <img
              className={cx("st-info-instructions-icon")}
              src={sendInfo}
              alt="st-info-instructions-send-info"
            />
            <div className={cx("st-info-instructions-label-set")}>
              <div className={cx("st-info-instructions-icon-title")}>Send</div>
              <div className={cx("st-info-instructions-icon-subtitle")}>
                Press the send button to submit your response.
              </div>
            </div>
          </div>
          <div className={cx("st-info-instructions-language-desc-container")}>
            <p className={cx("st-info-instructions-language-desc")}>
              Starting from the next screen, we will communicate in your Mother
              <br />
              tongue language which is{" "}
              <span className={cx("st-info-instructions-language-highlight")}>
                Chinese/Malay/Tamil.
              </span>
            </p>
          </div>
          <div className={cx("st-info-instructions-lets-start-btn-container")}>
            <StudentButtonCTA
              type="primary"
              text="Let's Start"
              onClick={handleStartBtn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalExerciseInstructions;
