import React, {
  createRef,
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
} from "react";
import classNames from "classnames/bind";
import styles from "./AudioBar.scss";
import recordingIcon from "../../assets/studentVoiceRecording.svg";
import recordingPlainIcon from "../../assets/mic-plain.svg";
import studentIcon from "../../assets/studentIcon.svg";
import {
  useAudioAnalyserStore,
  useAudioAnalyserSetup,
} from "store/student/useAudioAnalyserStore";

interface AudioBarProps {
  testId?: string;
  content?: string;
  clicked?: () => void;
  className?: string;
  height?: string;
  useStudentIcon?: boolean;
  type: string;
}

const AudioBar = ({
  testId,
  content,
  clicked,
  className,
  height,
  useStudentIcon = false,
  type,
}: AudioBarProps) => {
  const cx = classNames.bind(styles);
  const canvasRef = createRef<HTMLCanvasElement>();
  const containerRef = useRef<HTMLDivElement>(null);
  const analyser = useAudioAnalyserStore((state) => state.analyser);

  const [calculatedWidth, setCalculatedWidth] = useState<number>(0);
  const [calculatedHeight, setCalculatedHeight] = useState<number>(
    height ? parseInt(height, 10) : 100
  );

  // Initialize audio analyser and stream setup
  useAudioAnalyserSetup();

  const renderContent = (content: string | undefined) => {
    if (!content) return null;
    const maxLength = 50;
    const lines =
      content.match(new RegExp(".{1," + maxLength + "}", "g")) || [];

    return (
      <div className={cx("ai-prompt-text")}>
        {lines.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    );
  };

  // Adjust width on initial load and resize
  useLayoutEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const newWidth = Math.min(containerWidth * 0.75, 433);
        setCalculatedWidth(newWidth);
      }
    };

    // Use ResizeObserver to track changes in the container's width
    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Run the width calculation on initial mount as well
    updateDimensions();

    // Clean up the ResizeObserver on unmount
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []); // Empty dependency array ensures this runs once after mount

  useEffect(() => {
    if (!analyser || !canvasRef.current || calculatedWidth === 0) {
      return;
    }

    let raf: number;
    const data = new Uint8Array(analyser.frequencyBinCount);

    const draw = () => {
      raf = requestAnimationFrame(draw);
      analyser.getByteTimeDomainData(data);
      const canvas = canvasRef.current;
      if (canvas) {
        const { height, width } = canvas;
        const context = canvas.getContext("2d");
        let x = 0;
        const sliceWidth = (width * 1.0) / data.length;

        if (context) {
          context.lineWidth = 2;
          context.strokeStyle = type === "green" ? "#fff" : "#46c7c0";
          context.clearRect(0, 0, width, height);

          context.beginPath();
          context.moveTo(0, height / 2);
          for (const item of data) {
            const y = (item / 255.0) * height;
            context.lineTo(x, y);
            x += sliceWidth;
          }
          context.lineTo(x, height / 2);
          context.stroke();
        }
      }
    };
    draw();

    return () => {
      cancelAnimationFrame(raf);
    };
  }, [analyser, calculatedWidth, calculatedHeight]);

  return (
    <div
      data-testid={`audio-bar-${testId}`}
      className={cx("audio-bar-basic", className, {
        "with-text": useStudentIcon,
        [type]: type,
      })}
      onClick={clicked}
      ref={containerRef}
    >
      <img
        className={cx("audio-bar-icon")}
        src={
          type === "green"
            ? useStudentIcon
              ? studentIcon
              : recordingIcon
            : recordingPlainIcon
        }
        alt="audio icon"
      />
      <div className={cx("audio-bar-sounds")}>
        {!useStudentIcon && (
          <canvas
            width={calculatedWidth}
            height={calculatedHeight}
            ref={canvasRef}
            className={cx("audio-visualizer-canvas")}
          />
        )}
        {useStudentIcon && renderContent(content)}
      </div>
    </div>
  );
};

export default AudioBar;
