import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./AdminDelete.scss";
import NavigationBar from "../../../shared/navigationBar/NavigationBar";
import ModalCloseIcon from "../../../assets/ModalCloseIcon.svg";
import DeleteAdmin from "../../../assets/DeleteAdmin.svg";

const AdminDelete = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const [overlay, setOverlay] = useState(true);

  return (
    <div className={cx("so-admin-delete-container")}>
      <NavigationBar />
      {overlay && (
        <div className="so-admin-delete-overlay">
          <div className={cx("so-admin-delete-main-container")}>
            <img
              className={cx("so-admin-delete-close-icon")}
              src={ModalCloseIcon}
              alt="so-admin-delete-close-icon"
            />
            <div className={cx("so-admin-delete-label-container")}>
              <div className={cx("so-admin-delete-title")}>Delete Admin</div>
              <img
                className={cx("so-admin-delete-bin-icon")}
                src={DeleteAdmin}
                alt="so-admin-delete-bin-icon"
              />
              <div className={cx("so-admin-delete-btn-subtitle")}>
                Are you sure want to delete this admin ?
              </div>
            </div>
            <div className={cx("so-admin-delete-btn-container")}>
              <button className={cx("so-admin-delete-button-no")}>No</button>
              <button className={cx("so-admin-delete-button-yes")}>Yes</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDelete;
