import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./ModalResetEmail.scss";
import studentMailIcon from "../../../assets/letter.svg";
import studentMailFailedIcon from "../../../assets/studentMailFailedIcon.svg";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";
import { useLocation } from "react-router-dom";
import { resetParentEmail } from "services/api/parentApi";
import { useLogOut } from "hooks/useCheckExpiredToken";

const ModalResetEmail = () => {
  const cx = classNames.bind(styles);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const { doLogOut } = useLogOut();

  const [emailStatus, setEmailStatus] = useState<boolean>(false);

  const handleLoginBtn = () => {
    doLogOut();
  };

  useEffect(() => {
    if (token) {
      const verifyEmailToken = async () => {
        try {
          const response = await resetParentEmail(token);
          console.log("resetParentEmail POST", response);
          if (response && response.results === "Email reset successful") {
            setEmailStatus(true);
          } else {
            setEmailStatus(false);
          }
        } catch (error) {
          setEmailStatus(false);
          console.error("resetParentEmail POST", error);
        }
      };

      verifyEmailToken();
    } else {
      setEmailStatus(false);
    }
  }, [token]);

  return (
    <div className={cx("modal-email")}>
      <div className={cx("parent-email-verification-success-icon-label")}>
        {emailStatus ? (
          <>
            <img
              className={cx("email-verification-success-icon")}
              src={studentMailIcon}
              alt="student-parent-email-success-email-icon"
            />
            <div className={cx("parent-email-verification-label")}>
              Email Verification Successful
            </div>
            <div className={cx("parent-email-verification-subtitle")}>
              Cool! Your parent's email has been successfully verified.
            </div>
          </>
        ) : (
          <>
            <img
              className={cx("email-verification-failed-icon")}
              src={studentMailFailedIcon}
              alt="student-parent-email-success-email-icon"
            />
            <div className={cx("parent-email-verification-label", "orange")}>
              Email Verification Failed
            </div>
            <div className={cx("parent-email-verification-subtitle")}>
              Your parent's email is not verified. Please try again.
            </div>
          </>
        )}
        <div className={cx("parent-email-verification-cta")}>
          <StudentButtonCTA
            type="primary"
            text="Login"
            onClick={handleLoginBtn}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalResetEmail;
