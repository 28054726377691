import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./SettingsInfo.scss";
import NavigationBar from "../../../shared/navigationBar/NavigationBar";
import { Form, FormGroup, Input, Label } from "reactstrap";
// DEPRECATED: Fix webpack failed to parse source map compile warnings.
// Reference: https://stackoverflow.com/questions/71500112/antd-source-map-not-supported-in-react
// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
import systemOwnerSettingsChangePassword from "../../../assets/systemOwnerChangePassword.svg";

const SettingsInfo = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const firstName = "Chan";
  const middleName = "Heng";
  const lastName = "Chee";
  const email = "chanhenleng@gmail.com";

  return (
    <div className={cx("so-settings-container")}>
      <NavigationBar />
      <div className={cx("so-settings-white-container")}>
        <div className={cx("so-settings-title")}>Settings</div>

        <Form className={cx("so-settings-form-inputs")}>
          <div className={cx("so-settings-info-container")}>
            <div className={cx("so-settings-form-title")}>My Info</div>
            <FormGroup floating>
              <Input
                className={cx("so-settings-input")}
                id="so-settings-first-name-input"
                name="text"
                placeholder="First Name"
                type="text"
                value={firstName}
                disabled={true}
              />
              <Label
                className={cx("so-settings-label")}
                for="so-settings-label-first-name"
              >
                First Name
              </Label>
            </FormGroup>
            <FormGroup floating>
              <Input
                className={cx("so-settings-input")}
                id="so-settings-middle-name-input"
                name="text"
                placeholder="Middle Name (Optional)"
                type="text"
                value={middleName}
                disabled={true}
              />
              <Label
                className={cx("so-settings-label")}
                for="so-settings-label-middle-name"
              >
                Middle Name (Optional)
              </Label>
            </FormGroup>
            <FormGroup floating>
              <Input
                className={cx("so-settings-input")}
                id="so-settings-last-name-input"
                name="text"
                placeholder="Last Name"
                type="text"
                value={lastName}
                disabled={true}
              />
              <Label
                className={cx("so-settings-label")}
                for="so-settings-label-last-name"
              >
                Last Name
              </Label>
            </FormGroup>
            <FormGroup floating>
              <Input
                className={cx("so-settings-input")}
                id="so-settings-email-input"
                name="text"
                placeholder="Admin's Email"
                type="text"
                value={email}
                disabled={true}
              />
              <Label
                className={cx("so-settings-label")}
                for="so-settings-label-admin-email"
              >
                Admin's Email
              </Label>
            </FormGroup>
          </div>
        </Form>
        <div className={cx("so-settings-change-password-container")}>
          <div className={cx("so-settings-change-password-title")}>
            Change Password
          </div>

          <img
            className={cx("so-settings-change-password-icon")}
            src={systemOwnerSettingsChangePassword}
            alt="so-settings-change-password-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsInfo;
