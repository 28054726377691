import { create } from "zustand";

interface MicPermissionStore {
  isRecording: boolean;
  micTestStatus: string;
  micPermissionGranted: boolean | null;
  micPermissionState: "granted" | "denied" | "prompt" | null;
  error: string | null;
  microphones: MediaDeviceInfo[];
  speakers: MediaDeviceInfo[];
  selectedMic: string;
  selectedMicName: string;
  selectedSpeaker: string;
  showMicTimeout: boolean;
  isAudioPlaying: boolean;
  setIsRecording: (isRecording: boolean) => void;
  setShowMicTimeout: (show: boolean) => void;
  setIsAudioPlaying: (isPlaying: boolean) => void;
  checkMicPermission: () => Promise<void>;
  setMicPermissionState: (
    state: "granted" | "denied" | "prompt" | null
  ) => void;
  setMicrophones: (microphones: MediaDeviceInfo[]) => void;
  setSpeakers: (speakers: MediaDeviceInfo[]) => void;
  setSelectedMic: (micId: string) => Promise<void>;
  setSelectedSpeaker: (speakerId: string) => void;
  setMicTestStatus: (status: string) => void;
}

const useMicPermissionStore = create<MicPermissionStore>((set) => ({
  isRecording: false,
  micTestStatus: localStorage.getItem("mic-test-recording") || "not-started",
  micPermissionGranted: null,
  micPermissionState: null,
  error: null,
  microphones: [],
  speakers: [],
  selectedMic: localStorage.getItem("selected-microphone-id") || "",
  selectedMicName:
    localStorage.getItem("selected-microphone-name") || "Loading...",
  selectedSpeaker: localStorage.getItem("selected-speaker-id") || "",
  showMicTimeout: false,
  isAudioPlaying: false,

  setIsRecording: (isRecording) => set({ isRecording }),
  setShowMicTimeout: (show) => set({ showMicTimeout: show }),
  setIsAudioPlaying: (isPlaying) => set({ isAudioPlaying: isPlaying }),

  checkMicPermission: async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      set({
        micPermissionGranted: true,
        error: null,
        micPermissionState: "granted",
      });
    } catch (error: any) {
      set({
        micPermissionGranted: false,
        error: error.message || "Unknown error",
        micPermissionState: "denied",
      });
    }
  },

  setMicPermissionState: (state) => set({ micPermissionState: state }),

  setMicrophones: (microphones) => set({ microphones }),

  setSpeakers: (speakers) => set({ speakers }),

  setSelectedMic: async (micId) => {
    localStorage.setItem("selected-microphone-id", micId);
    let micName = "Unknown Microphone";
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const matchingDevice = devices.find(
        (device) => device.deviceId === micId
      );
      micName = matchingDevice?.label || "Unknown Microphone";
    } catch (error) {
      console.error("Error fetching microphone name:", error);
    }
    localStorage.setItem("selected-microphone-name", micName);
    set({ selectedMic: micId, selectedMicName: micName });
  },

  setSelectedSpeaker: (speakerId) => {
    localStorage.setItem("selected-speaker-id", speakerId);
    set({ selectedSpeaker: speakerId });
  },

  setMicTestStatus: (status) => {
    localStorage.setItem("mic-test-recording", status);
    set({ micTestStatus: status });
  },
}));

export default useMicPermissionStore;
