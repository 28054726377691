import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./SetupMicTest.scss";
import studentMicIcon from "../../../assets/studentMicIcon.svg";
import studentVerifyBtn from "../../../assets/studentVerifyBtn.svg";
import MicDetectedDevices from "../micDetectedDevices/MicDetectedDevices";
import MicBlocked from "../micBlocked/MicBlocked";
import useMicPermissionStore from "store/student/checkMicPermissionStore";

const SetupMicTest = () => {
  const cx = classNames.bind(styles);
  const {
    micPermissionGranted,
    error,
    checkMicPermission,
    micPermissionState,
  } = useMicPermissionStore();

  const [clickedMicAccess, setClickedMicAccess] = useState(false);

  const handleMicAccessClick = async () => {
    setClickedMicAccess(true);
    await checkMicPermission();
  };

  const handlePermissionGranted = () => {
    setClickedMicAccess(false);
  };

  useEffect(() => {
    if (micPermissionGranted === true) {
      handlePermissionGranted();
    }
  }, [micPermissionGranted]);

  return (
    <>
      {clickedMicAccess &&
        !micPermissionGranted &&
        micPermissionState !== "denied" && (
          <div
            className={cx("st-test-your-mic-overlay modal-overlay", "modal")}
          >
            <div className={cx("st-test-your-mic-overlay-popup")}>
              <div className={cx("st-test-your-mic-overlay-popup-text")}>
                Please click the "Allow" button
              </div>
            </div>
          </div>
        )}

      {/* If micPermissionGranted is null, show the initial setup screen */}
      {!micPermissionGranted && !error && !clickedMicAccess && (
        <div className={cx("st-test-your-mic-wrapper content")}>
          <div className={cx("st-test-your-mic-contents")}>
            <img
              className={cx("st-test-your-mic-icon")}
              src={studentMicIcon}
              alt="student-mic-icon"
            />
            <div className={cx("st-test-your-mic-title")}>
              Test Your Microphone
            </div>
            <div className={cx("st-test-your-mic-subtitle")}>
              Please click the “Allow” button on the next screen so we can use
              your microphone. <br /> Press “Verify” button to start.
            </div>
            <img
              className={cx("st-test-your-mic-verify-btn")}
              src={studentVerifyBtn}
              alt="st-test-your-mic-verify-btn"
              onClick={handleMicAccessClick}
            />
          </div>
        </div>
      )}

      {micPermissionGranted === false && <MicBlocked />}
      {micPermissionGranted === true && <MicDetectedDevices />}
    </>
  );
};

export default SetupMicTest;
