import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./DashboardProfile.scss";
import MicTest from "../setupMicTest/SetupMicTest";
import ChangeParentsEmail from "../dashboardChangeParentsEmail/DashboardChangeParentsEmail";
import { useStudentDashboardStore } from "store/student/useStudentDashboardStore";
import useMicPermissionStore from "store/student/checkMicPermissionStore";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";
import { getStudentProfile } from "services/api/studentApi";

const DashboardProfile = () => {
  const cx = classNames.bind(styles);
  const { selectedMicName } = useMicPermissionStore();
  const { studentProfile } = useStudentSetupStore();
  const { activeProfileSubpage, setActiveProfileSubpage } =
    useStudentDashboardStore();
  const { showModal, isTokenExpired, setStudentProfile, setIsTokenExpired } =
    useStudentSetupStore();

  const fetchStudentProfile = async () => {
    try {
      const profile = await getStudentProfile();
      setStudentProfile(profile);
      console.log("fetchStudentProfile GET after add", profile);
    } catch (error) {
      console.error("getStudentProfile:", error);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        return;
      } else {
        window.location.reload();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    fetchStudentProfile();
  }, [showModal]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (studentProfile.next_parent_token_request) {
        const tokenExpirationDate = new Date(
          studentProfile.next_parent_token_request
        );
        const currentDate = new Date();

        if (tokenExpirationDate < currentDate) {
          setIsTokenExpired(true);
        } else {
          setIsTokenExpired(false);
          const timeoutDuration =
            tokenExpirationDate.getTime() - currentDate.getTime();
          setTimeout(() => {
            setIsTokenExpired(true);
          }, timeoutDuration);
        }
      }
    };

    checkTokenExpiration();
  }, [studentProfile]);

  if (!studentProfile) {
    return <div>Loading...</div>;
  }

  return (
    <div className={cx("student-profile-container")}>
      {activeProfileSubpage === "profile" && (
        <>
          <div className={cx("profile-content content")}>
            <div className={cx("profile-name-email")}>
              <h1 className={cx("student-name")}>
                {studentProfile.first_name} {studentProfile.last_name}
              </h1>
              <span className={cx("student-email")}>
                {studentProfile.email}
              </span>
            </div>

            <div className={cx("info-item-rectangle")}>
              <div className={cx("info-profile-label-container")}>
                <span className={cx("info-label")}>Your Parent's Email</span>
                <span className={cx("info-value")}>
                  {studentProfile.parent_email}
                </span>
              </div>
              {studentProfile.next_parent_token_request ? (
                <>
                  <StudentButtonCTA
                    type={isTokenExpired ? "primary" : "inactive"}
                    text="Change"
                    onClick={
                      isTokenExpired
                        ? () => setActiveProfileSubpage("changeParentEmail")
                        : () => {}
                    }
                  />
                </>
              ) : (
                <>
                  <StudentButtonCTA
                    type="primary"
                    text="Change"
                    onClick={() => setActiveProfileSubpage("changeParentEmail")}
                  />
                </>
              )}
            </div>

            <div className={cx("info-item-rectangle")}>
              <div className={cx("info-profile-label-container")}>
                <span className={cx("info-label")}>Your Microphone</span>
                <span className={cx("info-value")}>{selectedMicName}</span>
              </div>
              <StudentButtonCTA
                type="primary"
                text="Test"
                onClick={() => setActiveProfileSubpage("micTest")}
              />
            </div>

            {navigator.userAgent.indexOf("Firefox") > -1 && (
              <div className={cx("info-label-firefox-warning")}>
                Firefox detected. Microphone may not be compatible.
                <br />
                You may need to change the setting to remember all microphones
              </div>
            )}
          </div>
        </>
      )}
      {activeProfileSubpage === "micTest" && <MicTest />}
      {activeProfileSubpage === "changeParentEmail" && <ChangeParentsEmail />}
    </div>
  );
};

export default DashboardProfile;
