import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./AdminMainDashboard.scss";
import Dropdown from "react-bootstrap/Dropdown";
import NavigationBar from "../../../../shared/navigationBar/NavigationBar";
import searchIcon from "../../../../assets/searchIcon.svg";

// DEPRECATED: previously @material-ui/core was installed,
// but it's removed as it conflicts with React v18
// import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import MuiTableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // MuiTableHead,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminMainDashboard = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(5);

  const checkIfCreateSingleAdminIsTrue = localStorage.getItem(
    "create-school-admin"
  );

  const checkIfBatchUploadOSchoolAdminIsTrue = localStorage.getItem(
    "batch-upload-school-admin"
  );

  useEffect(() => {
    if (checkIfCreateSingleAdminIsTrue === "true") {
      showtoastMsgIfSingleAdminCreated();
      localStorage.setItem("create-school-admin", "");
    }
    if (checkIfBatchUploadOSchoolAdminIsTrue === "true") {
      showtoastMsgIfBatchUploadSchoolAdminCreated();
      localStorage.setItem("batch-upload-school-admin", "");
    }
  }, [checkIfCreateSingleAdminIsTrue, checkIfBatchUploadOSchoolAdminIsTrue]);

  const showtoastMsgIfSingleAdminCreated = () => {
    toast.success("New admin has been successfully created.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      style: {
        backgroundColor: "#1BBF8D",
      },
    });
  };

  const showtoastMsgIfBatchUploadSchoolAdminCreated = () => {
    toast.success("File has been successfully uploaded.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      style: {
        backgroundColor: "#1BBF8D",
      },
    });
  };

  function handleChangePage(event: any, newpage: any) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event: any) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  // DEPRECATED: previously @material-ui/core was installed,
  // but it's removed as it conflicts with React v18
  // const useStyles = makeStyles({
  //   table: {
  //     opacity: 1,
  //     borderRadius: 2,
  //   },
  // });

  function createData(
    adminId: number,
    adminName: string,
    organisationName: string,
    action: any
  ) {
    return {
      adminId: adminId,
      adminName: adminName,
      organisation: organisationName,
      action: action,
    };
  }

  const rows = [
    createData(
      123456,
      "Adminal Hakim",
      "Admiralty Primary School",
      <a className={cx("so-sch-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      223456,
      "Bdminal Hakim",
      "Bdmiralty Primary School",
      <a className={cx("so-sch-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      323456,
      "Cdminal Hakim",
      "Cdmiralty Primary School",
      <a className={cx("so-sch-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      423456,
      "Ddminal Hakim",
      "Ddmiralty Primary School",
      <a className={cx("so-sch-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      523456,
      "Gdminal Hakim",
      "Gdmiralty Primary School",
      <a className={cx("so-sch-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      123456,
      "Adminal Hakim",
      "Admiralty Primary School",
      <a className={cx("so-sch-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      223456,
      "Bdminal Hakim",
      "Bdmiralty Primary School",
      <a className={cx("so-sch-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      323456,
      "Cdminal Hakim",
      "Cdmiralty Primary School",
      <a className={cx("so-sch-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      423456,
      "Ddminal Hakim",
      "Ddmiralty Primary School",
      <a className={cx("so-sch-main-dashboard-link")}>Full Details</a>
    ),
    createData(
      523456,
      "Gdminal Hakim",
      "Gdmiralty Primary School",
      <a className={cx("so-sch-main-dashboard-link")}>Full Details</a>
    ),
  ];

  // const classes = useStyles();

  const renderTableHead = () => {
    return (
      <TableHead className={cx("so-sch-main-dashboard-table-head-container")}>
        <TableRow>
          <TableCell className={cx("so-sch-main-dashboard-table-head-labels")}>
            <TableSortLabel>Admin ID</TableSortLabel>
          </TableCell>
          <TableCell
            className={cx("so-sch-main-dashboard-table-head-labels")}
            align="left"
          >
            <TableSortLabel>Admin Name</TableSortLabel>
          </TableCell>
          <TableCell
            className={cx("so-sch-main-dashboard-table-head-labels")}
            align="left"
          >
            <TableSortLabel>Organisation</TableSortLabel>
          </TableCell>
          <TableCell
            className={cx("so-sch-main-dashboard-table-head-labels")}
            align="left"
          >
            <TableSortLabel>Action</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const renderWholeTable = () => {
    return (
      <TableContainer className={cx("so-sch-main-dashboard-table-container")}>
        <Table>
          {renderTableHead()}
          <TableBody>
            {rows.map((row) => (
              <TableRow
                className={cx("so-sch-main-dashboard-table-row")}
                key={row.adminId}
              >
                <TableCell
                  className={cx("so-sch-main-dashboard-table-cell")}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.adminId}
                </TableCell>

                <TableCell
                  className={cx("so-sch-main-dashboard-table-cell")}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.adminName}
                </TableCell>
                <TableCell
                  className={cx("so-sch-main-dashboard-table-cell")}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.organisation}
                </TableCell>

                <TableCell
                  className={cx("so-sch-main-dashboard-table-cell")}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {row.action}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderSearchBar = () => {
    return (
      <div className={cx("so-sch-main-dashboard-searchbar-container")}>
        <div className={cx("so-sch-main-dashboard-merge")}>
          <input
            className={cx("so-sch-main-dashboard-input")}
            placeholder="Search by Organisation"
          />
          <div className={cx("so-sch-main-dashboard-search-icon-box")}>
            <img
              style={{ marginLeft: "22px", marginTop: "18px" }}
              className={cx("so-sch-main-dashboard-search-icon")}
              src={searchIcon}
              alt="system-owner-dashboard-search-icon"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderDropdownButton = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          className={cx("so-sch-main-dashboard-btn")}
          variant="success"
          id="dropdown-basic"
        >
          Create New &nbsp; &nbsp; &nbsp; &nbsp;
        </Dropdown.Toggle>

        <Dropdown.Menu className={cx("so-sch-main-dashboard-btn-menu")}>
          <Dropdown.Item className={cx("so-sch-main-dashboard-btn-menu-item")}>
            Create Single
          </Dropdown.Item>
          <Dropdown.Item className={cx("so-sch-main-dashboard-btn-menu-item")}>
            Upload Multiple
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderPagination = () => {
    return (
      <Stack spacing={2}>
        <Pagination
          className={cx("so-sch-main-dashboard-table-count")}
          count={10}
          shape="rounded"
        />
      </Stack>
    );
  };

  return (
    <div className={cx("so-sch-main-dashboard-container")}>
      <div className={cx("so-sch-main-dashboard-nav")}>
        <NavigationBar />
      </div>

      <div className={cx("so-sch-main-dashboard-white-container")}>
        <ToastContainer />
        <div className={cx("so-sch-main-dashboard-title-btn-container")}>
          <div className={cx("so-sch-main-dashboard-title")}>Organisations</div>
          {renderDropdownButton()}
        </div>
        {renderSearchBar()}
        {renderWholeTable()}
        <div className={cx("so-sch-main-dashboard-pagination-container")}>
          <TablePagination
            className={cx("so-sch-main-dashboard-table-pagination")}
            sx={{
              "& .MuiTablePagination-displayedRows": { display: "none" },
              "& .MuiTablePagination-actions": { display: "none" },
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rpg}
            page={pg}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {renderPagination()}
        </div>
      </div>
    </div>
  );
};

export default AdminMainDashboard;
