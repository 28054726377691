import React from "react";
import classNames from "classnames/bind";
import styles from "./ExerciseInitialMessage.scss";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";

interface ExerciseInitialMessageProps {
  isFirstTime: boolean;
  onCta: () => void;
}

const ExerciseInitialMessage = ({
  isFirstTime,
  onCta,
}: ExerciseInitialMessageProps) => {
  const cx = classNames.bind(styles);
  const { studentProfile } = useStudentSetupStore();
  const { setShowModal } = useStudentExerciseStore();

  const handleShowInstructions = () => {
    setShowModal("instructions");
  };

  const fullName =
    studentProfile.first_name && studentProfile.last_name
      ? `${studentProfile.first_name} ${studentProfile.last_name}`
      : studentProfile.first_name
        ? studentProfile.first_name
        : studentProfile.last_name;

  return (
    <div className={cx("student-information-chatbox")}>
      <div className={cx("student-information-description")}>
        {isFirstTime
          ? `Hello ${fullName}, welcome! Look at the picture and try to find the things, characters, and colours in it. When you're ready to talk about the picture, click the “Next” button.`
          : `Welcome back ${fullName}! Look at the picture and try to find the things, characters, and colours in it. When you're ready to talk about the picture, click the “Continue” button.`}
      </div>
      <div className={cx("floating")}>
        <StudentButtonCTA
          type="primary"
          text={isFirstTime ? "Next" : "Continue"}
          onClick={isFirstTime ? () => handleShowInstructions() : onCta}
        />
      </div>
    </div>
  );
};

export default ExerciseInitialMessage;
