import { jwtDecode } from "jwt-decode";

const checkIsTokenExpired = (token: string) => {
  if (!token) return true;

  try {
    const { exp } = jwtDecode(token) as { exp: number };
    return exp ? Date.now() >= exp * 1000 : true;
  } catch (error) {
    return true;
  }
};

export default checkIsTokenExpired;
