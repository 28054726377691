import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export interface Exercise {
  exercise_id: string;
  task: string | null;
  status: string;
  image_id: string;
  s3: string;
  start_date: string;
  end_date: string;
  last_interaction_date: string | null;
}

export interface ChatMessage {
  id: number;
  message: string;
}

export interface InputS3Url {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
    "x-amz-security-token": string;
  };
}

export interface S3UploadDetails {
  url: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
    securityToken: string;
  };
}

export interface BoundingBoxInfo {
  bounding_box_audio_id: string;
  s3_url: string;
  language: string;
  bounding_box_id: string;
  image_id: string;
  x: number;
  y: number;
  w: number;
  h: number;
  sequence: number;
}

export interface NextPrompt {
  response: {
    verdict: number;
    textInUtf8: string;
    soundFileUrl: string;
  };
  end: boolean;
}

interface StudentExerciseStore {
  selectedExercise: Exercise | null;
  chatHistory: ChatMessage[];
  showChatHistory: boolean;
  isFirstInterruption: boolean;
  hasCompletedExercise: boolean;
  exercises: Exercise[] | null;
  pastExercises: Exercise[] | null;
  hasIncomplete: boolean;
  showModal: string | null;
  s3UploadDetails: S3UploadDetails | null;
  nextPrompt: NextPrompt | null;
  isAiAudioPlaying: boolean;
  isPendingAiResponse: boolean;
  inputS3Url: InputS3Url | null;
  interactionMediaId: string;
  audioRef: React.RefObject<HTMLAudioElement>;
  audioBlob: Blob | null;
  audioBlobUrl: string | null;
  hasStarted: boolean;
  showMicInstruction: boolean;
  replayAudio: boolean;
  setSelectedExercise: (exercise: Exercise | null) => void;
  setChatHistory: (history: ChatMessage[]) => void;
  setShowChatHistory: (show: boolean) => void;
  setIsFirstInterruption: (isFirstInterruption: boolean) => void;
  setHasCompletedExercise: (hasCompletedExercise: boolean) => void;
  setExercises: (exercises: Exercise[] | null) => void;
  setPastExercises: (pastExercises: Exercise[] | null) => void;
  setHasIncomplete: (hasIncomplete: boolean) => void;
  setShowModal: (modalType: string | null) => void;
  setS3UploadDetails: (details: S3UploadDetails) => void;
  setNextPrompt: (prompt: NextPrompt | null) => void;
  setIsAiAudioPlaying: (isPlaying: boolean) => void;
  setIsPendingAiResponse: (isPending: boolean) => void;
  setInputS3Url: (data: InputS3Url) => void;
  setInteractionMediaId: (mediaId: string) => void;
  setAudioRef: (audioRef: React.RefObject<HTMLAudioElement>) => void;
  setAudioBlob: (blob: Blob | null) => void;
  setAudioBlobUrl: (url: string | null) => void;
  setHasStarted: (hasStarted: boolean) => void;
  setShowMicInstruction: (showMicInstruction: boolean) => void;
  setReplayAudio: (replayAudio: boolean) => void;
}

export const useStudentExerciseStore = create<StudentExerciseStore>()(
  persist(
    (set, get) => ({
      selectedExercise: null,
      chatHistory: [],
      showChatHistory: false,
      isFirstInterruption: true,
      hasCompletedExercise: false,
      exercises: null,
      pastExercises: null,
      hasIncomplete: false,
      showModal: null,
      s3UploadDetails: null,
      nextPrompt: null,
      isAiAudioPlaying: false,
      isPendingAiResponse: false,
      inputS3Url: null,
      interactionMediaId: "",
      audioRef: { current: null },
      audioBlob: null,
      audioBlobUrl: null,
      hasStarted: false,
      showMicInstruction: false,
      replayAudio: false,
      setSelectedExercise: (exercise) => set({ selectedExercise: exercise }),
      setChatHistory: (history) => set({ chatHistory: history }),
      setShowChatHistory: (show) => set({ showChatHistory: show }),
      setIsFirstInterruption: (isFirstInterruption) =>
        set({ isFirstInterruption }),
      setHasCompletedExercise: (hasCompletedExercise) =>
        set({ hasCompletedExercise }),
      setExercises: (exercises) => set({ exercises }),
      setPastExercises: (pastExercises) => set({ pastExercises }),
      setHasIncomplete: (hasIncomplete) => set({ hasIncomplete }),
      setShowModal: (modalType) => set({ showModal: modalType }),
      setS3UploadDetails: (details) => set({ s3UploadDetails: details }),
      setNextPrompt: (prompt) => set({ nextPrompt: prompt }),
      setIsAiAudioPlaying: (isPlaying) => set({ isAiAudioPlaying: isPlaying }),
      setIsPendingAiResponse: (isPending) =>
        set({ isPendingAiResponse: isPending }),
      setInputS3Url: (data) => set({ inputS3Url: data }),
      setInteractionMediaId: (mediaId) => set({ interactionMediaId: mediaId }),
      setAudioRef: (audioRef) => set({ audioRef }),
      setAudioBlob: (blob) => {
        if (blob) {
          set({ audioBlob: blob });
          const objectUrl = URL.createObjectURL(blob);
          set({ audioBlobUrl: objectUrl });
        } else {
          set({ audioBlobUrl: null });
        }
      },
      setAudioBlobUrl: (url) => set({ audioBlobUrl: url }),
      setHasStarted: (hasStarted) => set({ hasStarted }),
      setShowMicInstruction: (showMicInstruction) =>
        set({ showMicInstruction }),
      setReplayAudio: (replayAudio) => set({ replayAudio }),
    }),
    {
      name: "student-exercise",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
