import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./NavigationBarLoggedIn.scss";
import homeIcon from "../../assets/homeIcon.svg";
import historyIcon from "../../assets/historyIcon.svg";
import profileIcon from "../../assets/userProfileIcon.svg";
import logoutIcon from "../../assets/logoutIcon.svg";
import { useStudentDashboardStore } from "store/student/useStudentDashboardStore";
import circularClose from "assets/studentCircularClose.svg";
import { useLogOut } from "hooks/useCheckExpiredToken";

const NavigationBarLoggedIn = () => {
  const cx = classNames.bind(styles);
  const location = useLocation();
  const navigate = useNavigate();
  const { doLogOut } = useLogOut();

  const { setShowComponent, showComponent, setActiveProfileSubpage } =
    useStudentDashboardStore();

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleNavigation = (tab: "home" | "history" | "profile") => {
    setShowComponent(tab);
    setMobileMenuOpen(false);

    if (tab === "home") {
      navigate("/home", { replace: true });
    } else if (tab === "history") {
      navigate("/history", { replace: true });
    } else if (tab === "profile") {
      setActiveProfileSubpage("profile");
      navigate("/profile", { replace: true });
    }
  };

  const handleLogout = (event: any) => {
    event.preventDefault();
    doLogOut();
  };

  const toggleMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

  return (
    <nav className={cx("nav-bar-full-container")}>
      <div className={cx("nav-bar-icon-sets content")}>
        <div className={cx("nav-bar-menu-toggle")} onClick={toggleMenu}>
          <span className={cx("hamburger-icon")}>☰</span>
        </div>

        {isMobileMenuOpen && (
          <div className={cx("nav-bar-mobile-menu")}>
            <img
              className={cx("nav-bar-mobile-menu-close")}
              src={circularClose}
              alt="circular-close"
              onClick={toggleMenu}
            />
            <div
              className={cx("nav-bar-full-icon-set", {
                "nav-bar-full-active":
                  showComponent === "home" ||
                  location.pathname.startsWith("/home"),
              })}
              onClick={() => handleNavigation("home")}
            >
              <img src={homeIcon} alt="Home Icon" />
              <span>Home</span>
            </div>
            <div
              className={cx("nav-bar-full-icon-set", {
                "nav-bar-full-active":
                  showComponent === "history" ||
                  location.pathname.startsWith("/history"),
              })}
              onClick={() => handleNavigation("history")}
            >
              <img src={historyIcon} alt="Exercise History Icon" />
              <span>Exercise History</span>
            </div>
            <div
              className={cx("nav-bar-full-icon-set", {
                "nav-bar-full-active":
                  showComponent === "profile" ||
                  location.pathname.startsWith("/profile"),
              })}
              onClick={() => handleNavigation("profile")}
            >
              <img src={profileIcon} alt="Profile Icon" />
              <span>My Profile</span>
            </div>
            <div className={cx("nav-bar-full-icon-set")} onClick={handleLogout}>
              <img src={logoutIcon} alt="Logout Icon" />
              <span>Logout</span>
            </div>
          </div>
        )}

        <div className={cx("nav-bar-full-three-icon-sets")}>
          <div
            className={cx("nav-bar-full-icon-set", {
              "nav-bar-full-active":
                showComponent === "home" ||
                location.pathname.startsWith("/home"),
            })}
            onClick={() => handleNavigation("home")}
          >
            <img src={homeIcon} alt="Home Icon" />
            <span>Home</span>
          </div>
          <div
            className={cx("nav-bar-full-icon-set", {
              "nav-bar-full-active":
                showComponent === "history" ||
                location.pathname.startsWith("/history"),
            })}
            onClick={() => handleNavigation("history")}
          >
            <img src={historyIcon} alt="Exercise History Icon" />
            <span>Exercise History</span>
          </div>
          <div
            className={cx("nav-bar-full-icon-set", {
              "nav-bar-full-active":
                showComponent === "profile" ||
                location.pathname.startsWith("/profile"),
            })}
            onClick={() => handleNavigation("profile")}
          >
            <img src={profileIcon} alt="Profile Icon" />
            <span>My Profile</span>
          </div>
        </div>
        <div className={cx("nav-bar-full-icon-set")} onClick={handleLogout}>
          <img src={logoutIcon} alt="Logout Icon" />
          <span>Logout</span>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBarLoggedIn;
