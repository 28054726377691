import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogOut } from "hooks/useCheckExpiredToken";
import { useLoginState } from "store/useLoginStore";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";
import useMicPermissionStore from "store/student/checkMicPermissionStore";

const Protected = ({ children, path }) => {
  const navigate = useNavigate();
  const { doLogOut, timeOut, checkIsTokenExpired } = useLogOut();

  const token = useLoginState((state) => state.token);
  const userRole = useLoginState((state) => state.role);
  const { studentProfile } = useStudentSetupStore();
  const { selectedExercise } = useStudentExerciseStore();
  const { micPermissionState } = useMicPermissionStore();

  const systemOwnerPaths = [
    "/admin/system-owner-create-org-initial",
    "/admin/system-owner-create-org",
    "/admin/system-owner-admin-account-initial",
    "/admin/system-owner-admin-account",
    "/admin/system-owner-admin-main-dashboard",
    "/admin/system-owner-settings-info",
    "/admin/system-owner-password-changed",
    "/admin/system-owner-main-dashboard",
    "/admin/system-owner-organisation-details",
    "/admin/system-owner-multiple-organisation-upload",
    "/admin/system-owner-multiple-school-admin-upload",
    "/admin/system-owner-admin-delete",
  ];

  const studentPaths = [
    "/welcome",
    "/home",
    "/history",
    "/profile",
    "/exercise",
  ];

  const handleTokenExpiry = () => {
    if (checkIsTokenExpired(token)) {
      timeOut();
    }
  };

  const handleSystemOwnerNavigation = () => {
    if (systemOwnerPaths.includes(path)) {
      navigate(path, { replace: true });
    } else {
      doLogOut();
    }
  };

  const handleStudentNavigation = () => {
    if (!studentProfile?.parent_email) {
      navigate("/welcome", { replace: true });
      return;
    }

    switch (path) {
      case "/exercise":
        if (!selectedExercise) {
          navigate("/home", { replace: true });
        } else {
          navigate(`/exercise`, { replace: true });
        }
        break;
      case "/home":
        navigate(`/home`, { replace: true });
        break;
      case "/history":
        navigate(`/history`, { replace: true });
        break;
      case "/profile":
        navigate(`/profile`, { replace: true });
        break;
      default:
        navigate("/home", { replace: true });
        break;
    }
  };

  useEffect(() => {
    handleTokenExpiry();
    if (!userRole || !token) {
      doLogOut();
    } else {
      switch (userRole) {
        case "SYSTEM_OWNER":
          handleSystemOwnerNavigation();
          break;
        case "STUDENT":
          handleStudentNavigation();
          break;
        default:
          break;
      }
    }
  }, [navigate, token]);

  return children;
};

export default Protected;
