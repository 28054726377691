import React from "react";
import classNames from "classnames/bind";
import styles from "./ModalExerciseIncomplete.scss";
import circularClose from "assets/studentCircularClose.svg";
import nonSmiley from "assets/nonSmiley.svg";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";
import { useNavigate } from "react-router-dom";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";

interface ModalExerciseIncompleteProps {
  onClose: () => void;
}

const ModalExerciseIncomplete = ({ onClose }: ModalExerciseIncompleteProps) => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const { setShowModal } = useStudentExerciseStore();

  const handleGoToHome = () => {
    setShowModal(null);
    navigate("/home");
  };

  return (
    <div
      className={cx("incomplete-exercise-modal goback-to-home modal-overlay")}
    >
      <div className={cx("incomplete-exercise-modal-container")}>
        <img
          className={cx("incomplete-exercise-popup-close-btn")}
          src={circularClose}
          alt="circular-close"
          onClick={onClose}
        />
        <img
          className={cx("")}
          src={nonSmiley}
          alt="Oops"
          onClick={() => navigate(`/home`)}
        />
        <h1>Oops…</h1>
        <div className={cx("incomplete-exercise-popup-message")}>
          <p>
            If you go to the home page, this exercise will be marked as
            Incomplete, and you’ll need to continue it later.
          </p>
          <p>Are you sure you want to go to the home page?</p>
        </div>
        <div className={cx("modal-cta")}>
          <StudentButtonCTA
            type="secondary"
            text="No"
            onClick={onClose}

          />

          <StudentButtonCTA
            type="primary"
            text="Yes"
            onClick={() => handleGoToHome()}

          />

        </div>
      </div>
    </div>
  );
};

export default ModalExerciseIncomplete;
