import React, { useEffect } from "react";
import { useStudentDashboardStore } from "store/student/useStudentDashboardStore";
import History from "components/student/dashboardHistory/DashboardHistory";
import Home from "components/student/dashboardHome/DashboardHome";
import Profile from "components/student/dashboardProfile/DashboardProfile";
import NavigationBarLoggedIn from "shared/navigationBarLoggedIn/NavigationBarLoggedIn";
import styles from "./StudentDashboardScreen.scss";
import classNames from "classnames/bind";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";
import { getStudentProfile } from "services/api/studentApi";
import { useLogOut } from "hooks/useCheckExpiredToken";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";

interface StudentDashboardScreenProps {
  path: string;
}

const StudentDashboardScreen = ({ path }: StudentDashboardScreenProps) => {
  const cx = classNames.bind(styles);
  const { showComponent, setShowComponent } = useStudentDashboardStore();
  const { studentProfile, setStudentProfile } = useStudentSetupStore();
  const { setHasStarted, setSelectedExercise } = useStudentExerciseStore();
  const { doLogOut } = useLogOut();

  if (path === "/home" && showComponent !== "home") {
    setShowComponent("home");
  } else if (path === "/history" && showComponent !== "history") {
    setShowComponent("history");
  } else if (path === "/profile" && showComponent !== "profile") {
    setShowComponent("profile");
  }

  const renderContent = () => {
    switch (showComponent) {
      case "home":
        return <Home />;
      case "history":
        return <History />;
      case "profile":
        return <Profile />;
      default:
        return <Home />;
    }
  };

  useEffect(() => {
    setHasStarted(false);
    setSelectedExercise(null);
    if (!studentProfile) {
      const checkStudentProfile = async () => {
        try {
          const response = await getStudentProfile();
          if (response) {
            setStudentProfile(response);
          }
        } catch (error) {
          if (
            error instanceof Error &&
            error.message === "Token is required but not found"
          ) {
            console.error("Caught Token is missing");
            doLogOut();
          } else if (
            error instanceof Error &&
            error.message === "Signature has expired"
          ) {
            console.error("Caught Signature has expired");
            doLogOut();
          } else {
            console.log("getStudentProfile Api", error);
          }
        }
      };

      checkStudentProfile();
    }
  }, []);

  return (
    <div className={cx("student st-dashboard-screen")}>
      <NavigationBarLoggedIn />
      <div className={cx("st-dashboard-container")}>{renderContent()}</div>
    </div>
  );
};

export default StudentDashboardScreen;
