import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./LoginForgotPin.scss";
import { forgetPassword } from "services/api/studentApi";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";
import sentNewPinMail from "../../../assets/sentNewPinMail.svg";
import sadEmojiIcon from "../../../assets/nonSmiley.svg";
import { isValidEmail } from "utils/emailUtils";

interface LoginForgotPinProps {
  setIsForgotPin: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginForgotPin = ({ setIsForgotPin }: LoginForgotPinProps) => {
  const cx = classNames.bind(styles);

  const [email, setChangeEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [forgotPinStatus, setForgotPinStatus] = useState<
    "forgotPinForm" | "sentNewPin" | "noParentsEmail"
  >("forgotPinForm");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangeEmail(event.target.value);
    if (event.target.value === "") {
      setErrorMsg("");
      setFormSubmitted(false);
    }
  };

  const handleTryAgainClick = () => {
    setForgotPinStatus("forgotPinForm");
  };

  const handleBackToLoginClick = () => {
    window.location.reload();
  };

  const handleConfirmClick = async () => {
    setFormSubmitted(true);
    if (email.length === 0) {
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMsg("Please enter a valid email address");
      return;
    }

    try {
      const response = await forgetPassword(email);

      if (response.results === "Email has been sent") {
        setForgotPinStatus("sentNewPin");
      } else if (
        response.results === "No account found" ||
        response.results === "Parent Email Not Found, Please contact teacher"
      ) {
        setForgotPinStatus("noParentsEmail");
      }
      console.error("forgotPassword API: ", response);
    } catch (error: any) {
      setErrorMsg(
        error.message || "An error occurred. Please try again later."
      );
      console.error("forgotPassword API: ", error);
    }
  };

  return (
    <div
      className={cx("st-forgot-pin-rectangle-container", {
        "error-state": errorMsg,
      })}
    >
      {forgotPinStatus === "noParentsEmail" && (
        <>
          <div className={cx("st-forgot-pin-rectangle ooops")}>
            <div className={cx("st-forgot-pin-contents-noparentsemail")}>
              <div
                className={cx("forgot-pin-no-parent-email-mail-iconcontainer")}
              >
                <img
                  className={cx("forgot-pin-no-parent-email-mail-icon")}
                  src={sadEmojiIcon}
                  alt="forgot-pin-no-parent-email-icon"
                />
              </div>
              <div className={cx("forgot-pin-no-parent-email-title")}>
                Ooops!
              </div>
              <div className={cx("forgot-pin-no-parent-email-subtitle")}>
                We can't find your parent's email. Please contact your teacher.
              </div>
              <div className={cx("st-forgot-pin-cta-set")}>
                <div className={cx("st-forgot-pin-confirm-btn")}>
                  <StudentButtonCTA
                    type="primary"
                    text="Try Again"
                    onClick={handleTryAgainClick}
                  />
                </div>
                <div
                  className={cx("forgot-pin-no-parent-email-link")}
                  onClick={handleBackToLoginClick}
                >
                  Back to Login
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {forgotPinStatus === "sentNewPin" && (
        <>
          <div className={cx("st-forgot-pin-rectangle")}>
            <div className={cx("st-forgot-pin-contents-sentnewpin")}>
              <div className={cx("st-sent-new-pin-mail-iconcontainer")}>
                <img
                  className={cx("st-sent-new-pin-mail-icon")}
                  src={sentNewPinMail}
                  alt="st-sent-new-pin-icon"
                />
              </div>

              <div className={cx("st-sent-new-pin-title")}>Sent a New PIN</div>
              <div className={cx("st-sent-new-pin-subtitle")}>
                <span data-testid="email-sent-notification-sub-text">
                  We've sent a new PIN to your parent's email for{" "}
                </span>
                <span className={cx("st-sent-new-pin-email")}>{email}</span>
                <span data-testid="email-sent-notification-instruction">
                  {" "}
                  account. Please use the new PIN to log in.
                </span>
                <div style={{ marginTop: "1.5rem" }}>
                  <span data-testid="email-sent-notification-retry">
                    If you don't receive it, try again in a little while.
                  </span>
                </div>
                <div
                  onClick={() => handleBackToLoginClick()}
                  className={cx("st-sent-new-pin-link")}
                >
                  Back to Login
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {forgotPinStatus === "forgotPinForm" && (
        <>
          <div className={cx("st-forgot-pin-rectangle")}>
            <div className={cx("st-forgot-pin-contents-form")}>
              <div className={cx("st-forgot-pin-title")}>Forgot PIN</div>
              <div className={cx("st-forgot-pin-subtitle")}>
                Please enter your email to reset the PIN.
              </div>

              <div className={cx("st-forgot-pin-email-label")}>Your Email</div>
              <input
                className={
                  email.length === 0 && formSubmitted
                    ? cx("st-forgot-pin-input-error-highlight input")
                    : cx("st-forgot-pin-input input")
                }
                type="text"
                name="email"
                placeholder="Type your email here"
                value={email}
                onChange={handleEmailChange}
              />
              {email.length === 0 && formSubmitted && (
                <div className={cx("st-forgot-pin-field-validation")}>
                  Input required
                </div>
              )}
              {errorMsg && (
                <div className={cx("st-forgot-pin-field-validation")}>
                  {errorMsg}
                </div>
              )}
              <div className={cx("st-forgot-pin-cta-set")}>
                <div className={cx("st-forgot-pin-confirm-btn")}>
                  <StudentButtonCTA
                    type="primary"
                    text="Confirm"
                    onClick={handleConfirmClick}
                  />
                </div>

                <a href="/" className={cx("st-forgot-pin-link")}>
                  Back to Login
                </a>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginForgotPin;
