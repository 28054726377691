import React, { useEffect, useRef } from "react";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";

const AiAudioVisualizer = () => {
  const {
    audioBlobUrl,
    setIsAiAudioPlaying,
    setShowMicInstruction,
    setShowModal,
    selectedExercise,
    hasCompletedExercise,
    setAudioRef,
  } = useStudentExerciseStore();

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationFrameIdRef = useRef<number | null>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const sourceRef = useRef<MediaElementAudioSourceNode | null>(null);

  useEffect(() => {
    if (!audioBlobUrl) return;

    const newAudio = new Audio(audioBlobUrl);

    setAudioRef({ current: newAudio });

    const canvas = canvasRef.current;
    if (!canvas || !newAudio) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext ||
        window.AudioContext)();
    }

    if (!analyserRef.current) {
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 256;
    }

    if (sourceRef.current) {
      sourceRef.current.disconnect();
      sourceRef.current = null;
    }

    const source = audioContextRef.current.createMediaElementSource(newAudio);
    source.connect(analyserRef.current);
    analyserRef.current.connect(audioContextRef.current.destination);

    sourceRef.current = source;

    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const remToPx = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    const WIDTH = (canvas.width = 2.625 * remToPx);
    const HEIGHT = (canvas.height = 1.5625 * remToPx);

    const barsToRender = 4;
    const barWidth = 0.375 * remToPx;
    const gap = 0.375 * remToPx;
    const centerY = HEIGHT / 2;
    const totalBarsWidth = barsToRender * barWidth + (barsToRender - 1) * gap;
    const startX = (WIDTH - totalBarsWidth) / 2;

    const bufferHeight = HEIGHT * 0.2;
    const maxBarHeight = HEIGHT - bufferHeight;

    const renderFrame = () => {
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      analyserRef.current?.getByteFrequencyData(dataArray);

      const barOrder = [0, 2, 1, 3];

      let x = startX;
      for (let i = 0; i < barsToRender; i++) {
        let barHeight =
          dataArray[barOrder[i] * Math.floor(bufferLength / barsToRender)];

        if (barHeight === 0) barHeight = 10;

        const scaledBarHeight = barHeight / 2;
        const finalBarHeight = Math.min(scaledBarHeight, maxBarHeight);

        ctx.fillStyle = "#46b3f6";
        drawRoundedRect(
          ctx,
          x,
          centerY - finalBarHeight / 2,
          barWidth,
          finalBarHeight,
          barWidth / 2
        );
        x += barWidth + gap;
      }

      animationFrameIdRef.current = requestAnimationFrame(renderFrame);
    };

    newAudio.onplay = () => {
      renderFrame();
    };

    newAudio.onended = () => {
      if (animationFrameIdRef.current)
        cancelAnimationFrame(animationFrameIdRef.current);

      setIsAiAudioPlaying(false);

      if (
        selectedExercise?.status !== "COMPLETED" &&
        selectedExercise?.status !== "INCOMPLETE"
      ) {
        setShowMicInstruction(true);
      }
    };

    newAudio
      .play()
      .catch((error) => console.error("Error playing audio:", error));

    return () => {
      if (animationFrameIdRef.current)
        cancelAnimationFrame(animationFrameIdRef.current);

      if (sourceRef.current) {
        sourceRef.current.disconnect();
        sourceRef.current = null;
      }

      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }

      if (newAudio) {
        newAudio.pause();
        newAudio.srcObject = null;
      }
    };
  }, [
    audioBlobUrl,
    selectedExercise,
    hasCompletedExercise,
    setIsAiAudioPlaying,
    setShowMicInstruction,
    setShowModal,
    setAudioRef,
  ]);

  const drawRoundedRect = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number
  ) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
    ctx.lineTo(x + radius, y + height);
    ctx.arcTo(x, y + height, x, y + height - radius, radius);
    ctx.lineTo(x, y + radius);
    ctx.arcTo(x, y, x + radius, y, radius);
    ctx.closePath();
    ctx.fill();
  };

  return <canvas ref={canvasRef} style={{ display: "block" }} />;
};

export default AiAudioVisualizer;
