import React, { useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./MicBlocked.scss";
import studentBlockMic from "../../../assets/studentBlockMic.svg";

const MicBlocked: React.FC = () => {
  const cx = classNames.bind(styles);

  useEffect(() => {
    const setBlockedMic = async () => {
      try {
        await localStorage.setItem("blocked-mic", "true");
      } catch (error) {
        console.error("Error setting blocked-mic in localStorage", error);
      }
    };

    setBlockedMic();
  }, []);

  return (
    <>
      <div className={cx("st-block-mic-container content")}>
        <div className={cx("st-block-mic-contents")}>
          <img
            className={cx("st-block-mic-icon")}
            src={studentBlockMic}
            alt="student-block-mic-icon"
          />
          <div className={cx("st-block-mic-title")}>
            We are unable to access your microphone
          </div>
          <div className={cx("st-block-mic-subtitle")}>
            To use the microphone, we require your permission. Please ask your
            parent for permission to enable microphone access so you can use this
            feature.
          </div>
        </div>
      </div>
    </>
  );
};

export default MicBlocked;
