import React, { useEffect, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./MicRecording.scss";
import { useNavigate } from "react-router-dom";
import studentStopRecordingIcon from "assets/studentStopVoiceRecording.svg";
import studentTryAgainRecordingIcon from "assets/studentTryAgainAudio.svg";
import studentYesIHearBtn from "assets/studentYesIHearBtn.svg";
import studentNoTryAgainBtn from "assets/studentNoTryAgainBtn.svg";
import AudioBar from "shared/audioBar/AudioBar";
import { useMediaStreamStore } from "store/student/useMediaStreamStore";
import ModalInactiveMic from "components/student/modalInactiveMic/ModalInactiveMic";
import useMicPermissionStore from "store/student/checkMicPermissionStore";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";
import { useStudentDashboardStore } from "store/student/useStudentDashboardStore";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";

const MicRecording = () => {
  const cx = classNames.bind(styles);
  const navigate = useNavigate();
  const { start, stop } = useMediaStreamStore();
  const { checkOnboardingStatus, setShowComponent, setFinishedMicTest } =
    useStudentSetupStore();
  const { setActiveProfileSubpage } = useStudentDashboardStore();

  const {
    isRecording,
    setIsRecording,
    setMicTestStatus,
    showMicTimeout,
    setShowMicTimeout,
    setIsAudioPlaying,
  } = useMicPermissionStore();

  const [audioUrl, setAudioUrl] = React.useState<string | null>(null);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleStartRecording = async () => {
    try {
      start(true, false);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
          resetInactivityTimer();
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/mp4",
        });
        const url = URL.createObjectURL(audioBlob);
        setAudioUrl(url);
        setIsAudioPlaying(false);
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing the microphone:", error);
    }
  };

  const handleStopRecording = () => {
    stop();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach((track) => track.stop());
    }
    setIsRecording(false);
    setIsAudioPlaying(false);
  };

  const handleTryAgainRecording = () => {
    setAudioUrl(null);
    setIsAudioPlaying(false);
    handleStartRecording();
  };

  const handleConfirmHeardPlayback = () => {
    setFinishedMicTest(true);
    setMicTestStatus("completed");
    setShowComponent("default");
    const currentPath = window.location.pathname;
    const nextPage = checkOnboardingStatus ? "/profile" : "/welcome";

    if (currentPath === "/profile") {
      setActiveProfileSubpage("profile");
    } else {
      navigate(nextPage);
    }
  };

  const resetInactivityTimer = () => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    inactivityTimerRef.current = setTimeout(
      () => {
        setAudioUrl(null);
        setShowMicTimeout(true);
      },
      5 * 60 * 1000
    );
  };

  const restartRecordingProcess = () => {
    setAudioUrl(null);
    setIsAudioPlaying(false);
    setShowMicTimeout(false);
    handleStopRecording();
    handleStartRecording();
    resetInactivityTimer();
  };

  useEffect(() => {
    handleStartRecording();
    resetInactivityTimer();

    return () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
      handleStopRecording();
    };
  }, []);

  const handleModalClose = () => {
    setShowMicTimeout(false);
    restartRecordingProcess();
  };

  const handleModalCta = () => {
    setShowMicTimeout(false);
    restartRecordingProcess();
  };

  const renderRecording = () => (
    <div
      className={cx(
        "st-mic-test-recording-rectangle-container-start-recording"
      )}
    >
      <div className={cx("st-mic-test-recording-start-recording-label")}>
        Your voice is now recording..
      </div>
      <div className={cx("st-mic-test-recording-audio-container")}>
        <div className={cx("st-mic-test-recording-audio-bar")}>
          <AudioBar height={"43"} type="green" />
        </div>
        <img
          className={cx("st-mic-test-stop-record-mic-icon")}
          src={studentStopRecordingIcon}
          alt="Stop recording"
          onClick={handleStopRecording}
        />
      </div>
    </div>
  );

  const renderPlayback = () => (
    <div
      className={cx(
        "st-mic-test-recording-rectangle-container-start-recording"
      )}
    >
      <div className={cx("st-mic-test-recording-start-recording-label")}>
        <span>Recording has stopped.</span>
      </div>
      <div className={cx("st-mic-test-recording-audio-container")}>
        <div className={cx("st-mic-test-recording-audio-bar")}>
          {audioUrl && (
            <audio
              className={cx("audio-large")}
              src={audioUrl}
              controls
              onPlay={() => setIsAudioPlaying(true)}
              onPause={() => setIsAudioPlaying(false)}
            />
          )}
        </div>
        <img
          className={cx("st-mic-test-re-record-mic-icon")}
          src={studentTryAgainRecordingIcon}
          alt="Re-record"
          onClick={handleTryAgainRecording}
        />
      </div>
      <div className={cx("st-mic-test-recording-hear-verification-container")}>
        <div className={cx("hear-label")}>Can you hear what you recorded?</div>
        <div className={cx("hear-verification-btn-set")}>
          <StudentButtonCTA
            type="secondary"
            text="No, Try again"
            onClick={handleTryAgainRecording}
          />

          <StudentButtonCTA
            type="primary"
            text="Yes, I hear"
            onClick={handleConfirmHeardPlayback}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isRecording ? renderRecording() : audioUrl ? renderPlayback() : null}

      {showMicTimeout && (
        <ModalInactiveMic
          onClose={handleModalClose}
          onCta={handleModalCta}
          descType="setup"
        />
      )}
    </>
  );
};

export default MicRecording;
