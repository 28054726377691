import React, { useEffect, useState } from "react";
import DashboardExerciseCard from "../dashboardExerciseCard/DashboardExerciseCard";
import { initHotjar } from "helpers/Hotjar";
import Hotjar from "@hotjar/browser";
import classNames from "classnames/bind";
import styles from "./DashboardHome.scss";
import noExercisesIcon from "assets/no_exercises.svg";
import { useStudentExerciseStore } from "store/student/useStudentExerciseStore";
import { getLatestExercise } from "services/api/studentApi";

const DashboardHome = () => {
  const cx = classNames.bind(styles);
  const { exercises, setExercises } = useStudentExerciseStore();
  const [selectedFilter, setSelectedFilter] = useState<string>("Newest");

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
  };

  const filterExercises = (exercises: any[]) => {
    switch (selectedFilter) {
      case "Newest":
        return exercises.filter((exercise) => exercise.status !== "COMPLETED");

      case "Open":
        return exercises.filter((exercise) => exercise.status === "OPEN");

      case "Incomplete":
        return exercises.filter((exercise) => exercise.status === "INCOMPLETE");

      default:
        return exercises;
    }
  };

  const filteredExercises = filterExercises(exercises || []);

  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;
    if (hotjarSiteId && hotjarVersion) {
      initHotjar(Number(hotjarSiteId), Number(hotjarVersion));
      Hotjar.event("student_home");
    }
  }, []);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const response = await getLatestExercise();
        console.log("getLatestExercise response:", response);
        if (response && response.results) {
          setExercises(response.results);
        }
      } catch (err: any) {
        console.error("getLatestExercise error:", err.message || err);
      }
    };

    fetchExercises();
  }, [setExercises]);

  return (
    <div className={cx("student-dashboard-container content")}>
      <div className={cx("student-dashboard-filter-container")}>
        <div className={cx("student-dashboard-title")}>Exercises</div>
        <div className={cx("student-filter-btn-container")}>
          <div className={cx("student-filter-btn-container")}>
            {["Newest", "Open", "Incomplete"].map((filter) => (
              <div
                key={filter}
                className={cx(
                  selectedFilter === filter
                    ? "student-dashboard-selected-button"
                    : "student-dashboard-unselected-button"
                )}
                onClick={() => handleFilterClick(filter)}
              >
                <div className={cx("student-dashboard-label")}>{filter}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={cx("student-card-rows")}>
        {filteredExercises && filteredExercises.length > 0 ? (
          filteredExercises.map((exercise) => (
            <DashboardExerciseCard
              key={exercise.exercise_id}
              exercise={exercise}
            />
          ))
        ) : (
          <div className={cx("student-card-noexercises")}>
            <img src={noExercisesIcon} alt="No Exercises" />
            <p className={cx("desc grey")}>No exercises found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardHome;
