import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./NavigationBarHomeLogout.scss";
import homeIcon from "assets/homeIcon.svg";
import logoutIcon from "assets/logoutIcon.svg";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";
import { useLogOut } from "hooks/useCheckExpiredToken";

const NavigationBarHomeLogout = () => {
  const cx = classNames.bind(styles);
  const { setShowComponent } = useStudentSetupStore();
  const { doLogOut } = useLogOut();

  const handleLogout = (event: any) => {
    event.preventDefault();
    doLogOut();
  };

  const handleHomeClick = () => {
    setShowComponent("default");
  };

  return (
    <nav className={cx("nav-bar-home-logout-container")}>
      <div className={cx("nav-bar-home-widget content")}>
        <div
          className={cx(
            "nav-bar-home-logout-icon-set",
            "nav-bar-home-icon-label-set-spacing"
          )}
        >
          <img className={cx("home-icon")} src={homeIcon} alt="Home Icon" />
          <button
            onClick={handleHomeClick}
            className={cx("nav-bar-home-logout-text-label")}
          >
            Home
          </button>
        </div>
        <div className={cx("nav-bar-home-logout-icon-set")}>
          <img
            className={cx("logout-icon")}
            src={logoutIcon}
            alt="Logout Icon"
          />
          <Link
            to="#"
            onClick={handleLogout}
            className={cx("nav-bar-home-logout-text-label")}
          >
            Logout
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBarHomeLogout;
