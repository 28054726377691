import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface StudentSetupState {
  studentProfile: {
    mtl_name: string;
    mtl_language: string;
    first_name: string;
    last_name: string;
    preferred_name: string;
    phone_number: string;
    email: string;
    parent_email: string;
    next_parent_token_request: string;
  };
  finishedMicTest: boolean;
  isTokenExpired: boolean;
  startBtnVisible: boolean;
  showComponent: "default" | "addParentsEmail" | "micTest" | null;
  showModal: boolean;
  parentsConfirmEmail: string;
  checkOnboardingStatus: string | null;

  setStudentProfile: (profile: {
    mtl_name: string;
    mtl_language: string;
    first_name: string;
    last_name: string;
    preferred_name: string;
    phone_number: string;
    email: string;
    parent_email: string;
    next_parent_token_request: string;
  }) => void;
  setFinishedMicTest: (finished: boolean) => void;
  setIsTokenExpired: (pending: boolean) => void;
  setStartBtnVisible: (visible: boolean) => void;
  setShowComponent: (
    component: "default" | "addParentsEmail" | "micTest" | null
  ) => void;
  setShowModal: (visible: boolean) => void;
  setParentsConfirmEmail: (email: string) => void;
  setCheckOnboardingStatus: (status: string | null) => void;
}

export const useStudentSetupStore = create<StudentSetupState>()(
  persist(
    (set) => ({
      studentProfile: {
        mtl_name: "",
        mtl_language: "",
        first_name: "",
        last_name: "",
        preferred_name: "",
        phone_number: "",
        email: "",
        parent_email: "",
        next_parent_token_request: "",
      },
      finishedMicTest: false,
      isTokenExpired: false,
      startBtnVisible: false,
      showComponent: "default",
      showModal: false,
      parentsConfirmEmail: "",
      checkOnboardingStatus: null,

      setStudentProfile: (profile) => set({ studentProfile: profile }),
      setFinishedMicTest: (finished) => set({ finishedMicTest: finished }),
      setIsTokenExpired: (pending) => set({ isTokenExpired: pending }),
      setStartBtnVisible: (visible) => set({ startBtnVisible: visible }),
      setShowComponent: (component) => set({ showComponent: component }),
      setShowModal: (visible) => set({ showModal: visible }),
      setParentsConfirmEmail: (email) => set({ parentsConfirmEmail: email }),
      setCheckOnboardingStatus: (status) =>
        set({ checkOnboardingStatus: status }),
    }),
    {
      name: "student-setup",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
