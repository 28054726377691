import classNames from "classnames/bind";
import styles from "./ModalMicIdentifyButtons.scss";
import micInfo from "../../../assets/studentMicInfo.svg";
import circularClose from "../../../assets/studentCircularClose.svg";
import studentStopRecordingIcon from "../../../assets/studentStopVoiceRecording.svg";
import studentPlayRecordingIcon from "../../../assets/studentPlayAudio.svg";
import studentTryAgainRecordingIcon from "../../../assets/studentTryAgainAudio.svg";
import studentPauseIcon from "../../../assets/studentPause.svg";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";

interface ModalMicIdentifyButtonsProps {
  setShowModal: any;
}

const ModalMicIdentifyButtons = ({
  setShowModal,
}: ModalMicIdentifyButtonsProps) => {
  const cx = classNames.bind(styles);

  return (
    <div className={cx("st-detected-devices-overlay modal-overlay")}>
      <div className={cx("st-detected-devices-panel")}>
        <img
          className={cx("st-detected-devices-close-btn")}
          src={circularClose}
          alt="circular-close"
          onClick={() => setShowModal(false)}
        />
        <div className={cx("st-detected-devices-instructions-desc-container")}>
          <div className={cx("st-detected-devices-instructions-title")}>
            Cool! Let's identify the buttons
          </div>

          <div className={cx("st-detected-devices-instructions-icon-set")}>
            <img
              className={cx("st-detected-devices-instructions-icon")}
              src={micInfo}
              alt="st-detected-devices-instructions-mic-info"
            />
            <div className={cx("st-detected-devices-instructions-label-set")}>
              <div
                className={cx("st-detected-devices-instructions-icon-title")}
              >
                Mic
              </div>
              <div
                className={cx("st-detected-devices-instructions-icon-subtitle")}
              >
                Press the microphone button to start the recording.
              </div>
            </div>
          </div>

          <div className={cx("st-detected-devices-instructions-icon-set")}>
            <img
              className={cx("st-detected-devices-instructions-icon")}
              src={studentStopRecordingIcon}
              alt="st-detected-devices-instructions-mic-info"
            />
            <div className={cx("st-detected-devices-instructions-label-set")}>
              <div
                className={cx("st-detected-devices-instructions-icon-title")}
              >
                Stop
              </div>
              <div
                className={cx("st-detected-devices-instructions-icon-subtitle")}
              >
                Press the stop button to finish the recording.
              </div>
            </div>
          </div>

          <div className={cx("st-detected-devices-instructions-icon-set")}>
            <img
              className={cx("st-detected-devices-instructions-icon")}
              src={studentPlayRecordingIcon}
              alt="st-detected-devices-instructions-mic-info"
            />
            <div className={cx("st-detected-devices-instructions-label-set")}>
              <div
                className={cx("st-detected-devices-instructions-icon-title")}
              >
                Play
              </div>
              <div
                className={cx("st-detected-devices-instructions-icon-subtitle")}
              >
                Press the play button to play your recording.
              </div>
            </div>
          </div>

          <div className={cx("st-detected-devices-instructions-icon-set")}>
            <img
              className={cx("st-detected-devices-instructions-icon")}
              src={studentPauseIcon}
              alt="st-detected-devices-instructions-mic-info"
            />
            <div className={cx("st-detected-devices-instructions-label-set")}>
              <div
                className={cx("st-detected-devices-instructions-icon-title")}
              >
                Pause
              </div>
              <div
                className={cx("st-detected-devices-instructions-icon-subtitle")}
              >
                Press the pause button to pause your recording.
              </div>
            </div>
          </div>

          <div className={cx("st-detected-devices-instructions-icon-set")}>
            <img
              className={cx("st-detected-devices-instructions-icon")}
              src={studentTryAgainRecordingIcon}
              alt="st-detected-devices-instructions-mic-info"
            />
            <div className={cx("st-detected-devices-instructions-label-set")}>
              <div
                className={cx("st-detected-devices-instructions-icon-title")}
              >
                Try again
              </div>
              <div
                className={cx("st-detected-devices-instructions-icon-subtitle")}
              >
                Press the try again button to start a new recording.
              </div>
            </div>
          </div>
          <div className={cx("st-info-instructions-lets-start-btn-container")}>
            <StudentButtonCTA
              type="primary"
              text="Got It"
              onClick={() => setShowModal(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalMicIdentifyButtons;
