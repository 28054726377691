import { create } from "zustand";

interface StudentDashboardState {
  showComponent: "home" | "history" | "profile" | null;
  activeProfileSubpage: "profile" | "micTest" | "changeParentEmail";
  setShowComponent: (component: "home" | "history" | "profile" | null) => void;
  setActiveProfileSubpage: (
    subpage: "profile" | "micTest" | "changeParentEmail"
  ) => void;
}

export const useStudentDashboardStore = create<StudentDashboardState>(
  (set) => ({
    showComponent: "home",
    activeProfileSubpage: "profile",
    setShowComponent: (component) => set({ showComponent: component }),
    setActiveProfileSubpage: (subpage) =>
      set({ activeProfileSubpage: subpage }),
  })
);
