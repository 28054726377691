import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./CreateOrganisation.scss";
import NavigationBar from "../../../shared/navigationBar/NavigationBar";
import leftChevron from "../../../assets/leftChevron.svg";
import { Form, FormGroup, Input, Label } from "reactstrap";
import CustomButton from "../../../shared/customButton/CustomButton";
import { Select } from "antd";
// DEPRECATED: Fix webpack failed to parse source map compile warnings.
// Reference: https://stackoverflow.com/questions/71500112/antd-source-map-not-supported-in-react
// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
import FloatDropDown from "../../../shared/floatDropdown/FloatDropdown";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createSingleOrganisation } from "config/WebServiceConfig";

const { Option } = Select;

const CreateOrganisation = () => {
  const navigate = useNavigate();
  const cx = classNames.bind(styles);

  const [organisationName, setChangeOrganisationName] = useState("");

  const [selectValueStudents, setSelectValueStudents] = useState("");
  const [selectValueTeachers, setSelectValueTeachers] = useState("");
  const [selectValueClasses, setSelectValueClasses] = useState("");
  const [selectValueAdmins, setSelectValueAdmins] = useState("");

  const handleOrganisationNameChange = (event: any) => {
    setChangeOrganisationName(event.target.value);
  };

  const postCreateSingleOrganisation = async (
    organisationName: string,
    selectValueStudents: number,
    selectValueTeachers: number,
    selectValueClasses: number,
    selectValueAdmins: number
  ) => {
    try {
      await createSingleOrganisation({
        org_name: organisationName,
        student_range: selectValueStudents,
        teacher_range: selectValueTeachers,
        classes_range: selectValueClasses,
        admin_range: selectValueAdmins,
      });
      localStorage.setItem("create-organisation", "true");
      navigate("/admin/system-owner-main-dashboard");
    } catch (err: any) {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        style: {
          backgroundColor: "#F05959",
        },
      });
    }
  };

  return (
    <div className={cx("so-crte-org-container")}>
      <NavigationBar />
      <div className={cx("so-org-white-container")}>
        <ToastContainer />
        <div
          className={cx("create-org-back-container")}
          onClick={() => navigate("/admin/system-owner-create-org-initial")}
        >
          <img
            className={cx("so-nav-left-chevron")}
            src={leftChevron}
            alt="system-owner-go-back-chevron"
          />
          <div className={cx("so-nav-back-text")}>Go Back</div>
        </div>
        <div className={cx("so-create-org-title")}>Create Organisation</div>
        <div className={cx("so-create-org-details")}>
          Organisation's Details
        </div>
        <Form className={cx("so-create-org-form-inputs")}>
          <FormGroup floating>
            <Input
              className={cx("so-create-org-name-input")}
              id="so-org-name-input"
              name="text"
              placeholder="Organisation Name"
              type="text"
              value={organisationName}
              onChange={handleOrganisationNameChange}
            />
            <Label
              className={cx("so-create-org-name-label")}
              for="so-org-name-label"
            >
              Organisation Name
            </Label>
          </FormGroup>
          <div className={cx("so-floating-dropdown-container")}>
            <FloatDropDown
              label="Max Number of Students"
              value={selectValueStudents}
            >
              <Select
                className={cx("so-select")}
                onChange={(value: string) => setSelectValueStudents(value)}
                value={selectValueStudents}
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
                <Option value="70">70</Option>
                <Option value="80">80</Option>
                <Option value="90">90</Option>
                <Option value="100">100</Option>
              </Select>
            </FloatDropDown>
          </div>
          <div className={cx("so-floating-dropdown-container")}>
            <FloatDropDown
              label="Max Number of Teachers"
              value={selectValueTeachers}
            >
              <Select
                className={cx("so-select")}
                onChange={(value: string) => setSelectValueTeachers(value)}
                value={selectValueTeachers}
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
                <Option value="70">70</Option>
                <Option value="80">80</Option>
                <Option value="90">90</Option>
                <Option value="100">100</Option>
              </Select>
            </FloatDropDown>
          </div>

          <div className={cx("so-floating-dropdown-container")}>
            <FloatDropDown
              label="Max Number of Classes"
              value={selectValueClasses}
            >
              <Select
                className={cx("so-select")}
                onChange={(value: string) => setSelectValueClasses(value)}
                value={selectValueClasses}
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
                <Option value="70">70</Option>
                <Option value="80">80</Option>
                <Option value="90">90</Option>
                <Option value="100">100</Option>
              </Select>
            </FloatDropDown>
          </div>

          <div className={cx("so-floating-dropdown-container")}>
            <FloatDropDown
              label="Max Number of Admins"
              value={selectValueAdmins}
            >
              <Select
                className={cx("so-select")}
                onChange={(value: string) => setSelectValueAdmins(value)}
                value={selectValueAdmins}
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
                <Option value="70">70</Option>
                <Option value="80">80</Option>
                <Option value="90">90</Option>
                <Option value="100">100</Option>
              </Select>
            </FloatDropDown>
          </div>
        </Form>
        <CustomButton
          testId="so-create-org-btn"
          className="so-create-org-btn"
          content="Create"
          createOrganisationFlag={true}
          clicked={() =>
            postCreateSingleOrganisation(
              organisationName,
              parseInt(selectValueStudents),
              parseInt(selectValueTeachers),
              parseInt(selectValueClasses),
              parseInt(selectValueAdmins)
            )
          }
        ></CustomButton>
      </div>
    </div>
  );
};

export default CreateOrganisation;
