import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Protected from "helpers/Protected";
import ReactGA from "react-ga4";
import { initHotjar } from "helpers/Hotjar";
import PageTracking from "helpers/PageTracking";
import ToasterMain from "helpers/ToasterMain";

import StudentLoginScreen from "screens/student/studentLoginScreen/StudentLoginScreen";
import StudentExercise from "screens/student/studentExerciseScreen/StudentExerciseScreen";
import StudentDashboardScreen from "screens/student/studentDashboardScreen/StudentDashboardScreen";
import StudentWelcome from "screens/student/studentSetupScreen/StudentSetupScreen";
import StudentResetEmail from "screens/student/studentResetScreen/StudentResetScreen";

import SystemOwnerLogin from "screens/systemOwner/login/Login";
import SystemOwnerForgetPw from "screens/systemOwner/forgetPassword/ForgetPw";
import SystemOwnerResetPw from "screens/systemOwner/resetPassword/ResetPassword";
import SystemOwnerCreateOrgInit from "screens/systemOwner/createOrganisationInitial/CreateOrganisationInitial";
import SystemOwnerCreateOrg from "screens/systemOwner/createOrganisation/CreateOrganisation";
import SystemOwnerAdminAcc from "screens/systemOwner/adminAccount/AdminAccount";
import SystemOwnerAdminAccInit from "screens/systemOwner/adminAccountInitial/AdminAccountInitial";
import SystemOwnerSettingsInfo from "screens/systemOwner/settings/SettingsInfo";
import SystemOwnerPasswordChanged from "screens/systemOwner/passwordChanged/PasswordChanged";
import SystemOwnerMainDashboard from "screens/systemOwner/mainDashboard/MainDashboard";
import SystemOwnerAdminMainDashboard from "screens/systemOwner/adminAccount/adminMainDashboard/AdminMainDashboard";
import SystemOwnerOrganisationDetails from "screens/systemOwner/organisationDetails/OrganisationDetails";
import SystemOwnerMultiOrganisation from "screens/systemOwner/multiOrganisation/MultiOrganisation";
import SystemOwnerAdminDelete from "screens/systemOwner/adminDelete/AdminDelete";
import SystemOwnerMultiSchoolAdmin from "screens/systemOwner/multiSchoolAdmin/MultiSchoolAdmin";
import "antd/dist/antd.min.css";
import { useLoginState } from "store/useLoginStore";

const TRACKING_ID = process.env.REACT_APP_GA_KEY;

if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

const siteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION);
const enableHotjar = process.env.REACT_APP_ENABLE_HOTJAR === "true";

if (enableHotjar && !isNaN(siteId) && !isNaN(hotjarVersion)) {
  initHotjar(siteId, hotjarVersion);
  console.log("Hotjar is turned ON and initialized.");
} else {
  console.log("Hotjar is turned OFF or not properly configured.");
}

const AppNavigator = () => {
  const { token } = useLoginState();

  return (
    <Router>
      <PageTracking />
      {ToasterMain()}
      <Routes>
        {/* System Owner Non Protected Routes */}
        <Route
          path="/admin/system-owner-login"
          element={<SystemOwnerLogin />}
        />
        <Route
          path="/admin/system-owner-forget-password"
          element={<SystemOwnerForgetPw />}
        />
        <Route
          path="/admin/system-owner-reset-password"
          element={<SystemOwnerResetPw />}
        />
        {/* System Owner Protected Routes */}
        <Route
          path="/admin/system-owner-create-org-initial"
          element={
            <Protected path="/admin/system-owner-create-org-initial">
              <SystemOwnerCreateOrgInit />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-create-org"
          element={
            <Protected path="/admin/system-owner-create-org">
              <SystemOwnerCreateOrg />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-admin-account-initial"
          element={
            <Protected path="/admin/system-owner-admin-account-initial">
              <SystemOwnerAdminAccInit />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-admin-account"
          element={
            <Protected path="/admin/system-owner-admin-account">
              <SystemOwnerAdminAcc />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-admin-main-dashboard"
          element={
            <Protected path="/admin/system-owner-admin-main-dashboard">
              <SystemOwnerAdminMainDashboard />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-settings-info"
          element={
            <Protected path="/admin/system-owner-settings-info">
              <SystemOwnerSettingsInfo />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-password-changed"
          element={
            <Protected path="/admin/system-owner-password-changed">
              <SystemOwnerPasswordChanged />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-main-dashboard"
          element={
            <Protected path="/admin/system-owner-main-dashboard">
              <SystemOwnerMainDashboard />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-organisation-details"
          element={
            <Protected path="/admin/system-owner-organisation-details">
              <SystemOwnerOrganisationDetails />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-multiple-organisation-upload"
          element={
            <Protected path="/admin/system-owner-multiple-organisation-upload">
              <SystemOwnerMultiOrganisation />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-multiple-school-admin-upload"
          element={
            <Protected path="/admin/system-owner-multiple-school-admin-upload">
              <SystemOwnerMultiSchoolAdmin />
            </Protected>
          }
        />
        <Route
          path="/admin/system-owner-admin-delete"
          element={
            <Protected path="/admin/system-owner-admin-delete">
              <SystemOwnerAdminDelete />
            </Protected>
          }
        />
        {/* Student Protected Routes */}{" "}
        <Route
          path="/welcome"
          element={
            <Protected path="/welcome">
              <StudentWelcome />
            </Protected>
          }
        />
        <Route
          path="/home"
          element={
            <Protected path="/home">
              <StudentDashboardScreen path="/home" />
            </Protected>
          }
        />
        <Route
          path="/history"
          element={
            <Protected path="/history">
              <StudentDashboardScreen path="/history" />
            </Protected>
          }
        />
        <Route
          path="/profile"
          element={
            <Protected path="/profile">
              <StudentDashboardScreen path="/profile" />
            </Protected>
          }
        />
        <Route
          path="/exercise"
          element={
            <Protected path="/exercise">
              <StudentExercise />
            </Protected>
          }
        />
        <Route path="/reset-email" element={<StudentResetEmail />} />
        <Route
          path="*"
          element={
            <Protected path="/home">
              <StudentLoginScreen />
            </Protected>
          }
        />
        <Route
          path="/"
          element={token ? <Navigate to="/home" /> : <StudentLoginScreen />}
        />
        <Route
          path="*"
          element={token ? <Navigate to="/home" /> : <StudentLoginScreen />}
        />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </Router>
  );
};

export default AppNavigator;
