import React, { useState, ChangeEvent } from "react";
import classNames from "classnames/bind";
import styles from "./SetupAddParentsEmail.scss";
import { changeParentEmail } from "services/api/studentApi";
import { useStudentSetupStore } from "store/student/useStudentSetupStore";
import { isValidEmail } from "utils/emailUtils";
import StudentButtonCTA from "shared/studentButtonCTA/StudentButtonCTA";

const SetupParentsEmail = () => {
  const cx = classNames.bind(styles);
  const { setParentsConfirmEmail, setShowComponent, setShowModal } =
    useStudentSetupStore();

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [parentsInputEmail, setParentsInputEmail] = useState<string>("");
  const [parentsInputTwoEmail, setParentsInputTwoEmail] = useState<string>("");

  const handleParentsEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setParentsInputEmail(event.target.value);
    if (event.target.value === "") {
      setErrorMsg("");
    }
  };

  const handleParentsConfirmEmailChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setParentsInputTwoEmail(event.target.value);
    setParentsConfirmEmail(event.target.value);
    if (event.target.value === "") {
      setErrorMsg("");
    }
  };

  const isFormValid = (): boolean => {
    return (
      parentsInputEmail === parentsInputTwoEmail &&
      isValidEmail(parentsInputEmail) &&
      isValidEmail(parentsInputTwoEmail)
    );
  };

  const handleVerifyButtonClick = async () => {
    if (isFormValid()) {
      try {
        await changeParentEmail(parentsInputTwoEmail);
        setShowComponent("default");
        setShowModal(true);
        console.log("changeParentEmail POST:", parentsInputTwoEmail);
      } catch (error) {
        setErrorMsg("Failed to verify parent email. Please try again.");
        console.log("changeParentEmail POST:", error);
      }
    } else {
      setErrorMsg("Emails do not match or are invalid.");
      console.log("changeParentEmail POST error");
    }
  };

  const isButtonDisabled = !isFormValid();

  return (
    <>
      <div className={cx("st-add-parents-email-contents content")}>
        <div className={cx("st-add-parents-email-title")}>
          Add Parent's Email
        </div>

        <div className={cx("st-add-parents-email-subtitle")}>
          Your Parent's Email
        </div>

        <input
          id="st-add-parents-email-input-type-email"
          className={cx("st-add-parents-email-input")}
          type="text"
          name="parentsEmail"
          placeholder="Type your parent's email here"
          value={parentsInputEmail || ""}
          onChange={handleParentsEmailChange}
        />

        <div className={cx("st-add-parents-email-subtitle")}>
          Confirm Parent's Email
        </div>

        <input
          id="st-add-parents-email-input-confirm-email"
          className={cx("st-add-parents-email-input")}
          type="text"
          name="parentsConfirmEmail"
          placeholder="Type your parent's email here"
          value={parentsInputTwoEmail || ""}
          onChange={handleParentsConfirmEmailChange}
        />

        {errorMsg && !isButtonDisabled && (
          <div className={cx("st-add-parents-email-error")}>{errorMsg}</div>
        )}

        <div className={cx("st-add-parents-email-verify-btn-container")}>

          <StudentButtonCTA
            type={isFormValid() ? "primary" : "inactive"}
            text="Verify"
            onClick={handleVerifyButtonClick}
          />
        </div>
      </div>
    </>
  );
};

export default SetupParentsEmail;
