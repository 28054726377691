import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export interface LoginState {
  token: string | null;
  role: string | null;
  setToken: (token: string) => void;
  setRole: (role: string) => void;
  clearLoginState: () => void;
}

export const useLoginState = create<LoginState>()(
  persist(
    (set) => ({
      token: null,
      role: null,
      setToken: (token: string) => set(() => ({ token })),
      setRole: (role: string) => set(() => ({ role })),
      clearLoginState: () =>
        set(() => ({
          token: null,
          role: null,
        })),
    }),
    {
      name: "login",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
